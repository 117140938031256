import React, { useState } from 'react';
import { IconSearch } from '~/assets/Icons';
import { Loading, TablePage, Input } from '~/components';
import reportsService from '~/services/reportsService';
import { Color, Line } from '../ManagersAssiduity/styles';
import ModalDetails from './ModalDetails';
import { BtSeeMore, Container, Content, Title } from './styles';

const CongregationFrequency: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [detailsReport, setDetailsReport] = useState(false);
  const [infoDetailsReport, setInfoDetailsReport] = useState([]);

  const [dataSource, setDataSource] = useState([
    {
      church: 'Valentina 1',
      members_number: 500,
      media: 200,
      attendance_percentage: 50,
      registros: [
        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },
        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },
        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },
        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },
        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },
        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },
        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },

        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },
        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },
        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },
        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },
        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },
        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },

        {
          name: 'EBD 1',
          date: '05/01/2023',
          member: 200,
          presente: 100,
          attendance_percentage: 50,
        },
      ],
    },
  ]);
  const [time, setTime] = useState<any>(null);

  const columns = [
    {
      title: 'Igreja',
      dataIndex: 'church',
      key: 'church',
    },
    {
      title: 'Pessoas na igreja',
      dataIndex: 'members_number',
      key: 'members_number',
    },
    {
      title: 'Media de presentes',
      dataIndex: 'media',
      key: 'attendance',
    },
    {
      dataIndex: 'attendance_percentage',
      key: 'attendance_percentage',
      render: (e: number | null) => (
        <Line>
          {e ? `${e}%` : ''} <Color cor={e} />
        </Line>
      ),
    },
    {
      dataIndex: 'registros',
      key: 'seeMore',
      width: '150px',
      render: (e: any) => (
        <BtSeeMore
          onClick={() => {
            setDetailsReport(true);
            setInfoDetailsReport(e);
          }}
        >
          Ver detalhes
        </BtSeeMore>
      ),
    },
  ];

  const doSearch = async (val: string) => {
    setLoading(true);
    const data = await reportsService.reportsFrequencyChurch({
      name: val === '' ? undefined : val,
    });
    setDataSource(data.result);
    setLoading(false);
  };

  // useEffect(() => {
  //   doSearch('');
  // }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {detailsReport && (
        <ModalDetails
          data={infoDetailsReport}
          onClose={() => setDetailsReport(false)}
        />
      )}
      <Title>Frequência da congregação</Title>
      <Container>
        <Content>
          <Input
            placeholder="Pesquisar igrejas"
            icon={<IconSearch />}
            value={input}
            onChange={e => {
              setInput(e.target.value);
              if (time !== null) {
                clearTimeout(time);
              }
              setTime(
                setTimeout(() => {
                  doSearch(e.target.value);
                }, 1000),
              );
            }}
          />

          <TablePage columns={columns} dataSource={dataSource} rowKey="id" />
        </Content>
      </Container>
    </>
  );
};

export default CongregationFrequency;
