import React, { useState } from 'react';
import { IconSearch } from '~/assets/Icons';
import { Page, Loading, TablePage, Input } from '~/components';
import { Container, Content, Title } from './styles';

const ReportsSent: React.FC = () => {
  const [loading] = useState(false);
  const [input, setInput] = useState('');

  const columns = [
    {
      title: 'Igreja',
      dataIndex: 'church',
      key: 'church',
    },
    {
      title: 'Presen',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'F. do dirigente',
      dataIndex: 'pastor_present',
      key: 'pastor_present',
    },
  ];

  const dataSource = [
    { church: 'Valentina 1', date: '50 presencas', pastor_present: '2 Faltas' },
    { church: 'Valentina 1', date: '50 presencas', pastor_present: '2 Faltas' },
    { church: 'Valentina 1', date: '50 presencas', pastor_present: '2 Faltas' },
    { church: 'Valentina 1', date: '50 presencas', pastor_present: '2 Faltas' },
    { church: 'Valentina 1', date: '50 presencas', pastor_present: '2 Faltas' },
  ];

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      <Title>Relatórios enviados</Title>
      <Container>
        <Content>
          <Input
            placeholder="Pesquisar igrejas"
            icon={<IconSearch />}
            onChange={e => setInput(e.target.value)}
          />
          <TablePage
            columns={columns}
            dataSource={dataSource.filter((element: any) =>
              element.church.toLowerCase().includes(input.toLowerCase()),
            )}
            rowKey="id"
          />
        </Content>
      </Container>
    </>
  );
};

export default ReportsSent;
