import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100%;
`;

export const BtEdit = styled.a`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Blue.Main};
  }
`;

export const BtTrash = styled('a')<{ disabled?: boolean }>`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Red};
    ${props => props.disabled && 'color: #c4c4c4;'}
  }
`;

export const Color = styled('div')<{ background: string }>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: ${props => props.background} !important;
`;
