import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { RiLockPasswordFill } from 'react-icons/ri';
import { SwitchIcon } from '~/components';
// import menu from '~/mocks/Menu.json';
import {
  Container,
  DropDown,
  ButtonMenu,
  SessionTitle,
  LinkMenu,
  ButtonMenuContent,
} from './styles';
import { usePageContext } from '~/hooks';

type typeInput = {
  [key: string]: string | number;
};

const ItemMenu: React.FC = () => {
  const [toggle, setToggle] = useState<typeInput>({});
  const { setCollapseMenu } = usePageContext();
  const menu = useSelector((state: any) => state.auth.data.menu);

  useEffect(() => {
    menu[0].children.map((e: any) => {
      if (e.children.length > 0) {
        setToggle(prevState => ({ ...prevState, [e.id.toString()]: false }));
      }
      return null;
    });
  }, [menu]);

  const children = (item: any) => {
    const multiChild = (child: any) => {
      if (child.children.length > 0) {
        return <div>{children(child)}</div>;
      }
      return (
        <LinkMenu
          onClick={() => setCollapseMenu(false)}
          to={child.link}
          key={`${child.id}-children`}
        >
          {child.name}
        </LinkMenu>
      );
    };

    return (
      <>
        <ButtonMenu
          key="container-dropdown"
          onClick={() =>
            setToggle(prevState => ({
              ...prevState,
              [item.id.toString()]: !prevState[item.id.toString()],
            }))
          }
        >
          <SwitchIcon name={item.icon} color="#323232" />
          <ButtonMenuContent>
            {item.name}
            <MdKeyboardArrowDown />
          </ButtonMenuContent>
        </ButtonMenu>
        {item.children.map((sub: any) => {
          return (
            <div>
              <DropDown
                key={`${sub.id}-dropdown`}
                className={toggle[item.id] ? '' : 'false'}
              >
                {multiChild(sub)}
              </DropDown>
            </div>
          );
        })}
      </>
    );
  };

  const childSessions = (item: any) => {
    if (item.children.length > 0) {
      return (
        <div key="container-child">
          {item.children.map((child: any) => {
            if (child.children.length > 0) {
              return (
                <div key={`${child.id}-childSessions`}>{children(child)}</div>
              );
            }
            return (
              <LinkMenu
                to={child.link}
                onClick={() => setCollapseMenu(false)}
                key={`${child.id}-linkmenu`}
                className="itensMenu"
              >
                <SwitchIcon name={child.icon} color="#323232" />
                {child.name}
              </LinkMenu>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const sessions = (item: any) => {
    if (item.session) {
      return (
        <div>
          <SessionTitle key={`${item.name}-session`}>{item.name}</SessionTitle>
        </div>
      );
    }
    return null;
  };

  return (
    <div key="container-container">
      {menu.map((item: any) => {
        return (
          <Container key={`${item.id}-container`}>
            {sessions(item)}
            {childSessions(item)}
            <LinkMenu
              to="/senha"
              onClick={() => setCollapseMenu(false)}
              key="AlterarASenha-linkmenu"
              className="itensMenu"
            >
              <RiLockPasswordFill />
              Alterar a senha
            </LinkMenu>
          </Container>
        );
      })}
    </div>
  );
};

export default ItemMenu;
