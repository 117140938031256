import { useDispatch } from 'react-redux';
import { IoLogOutOutline } from 'react-icons/io5';
import { SideMenu } from '~/components';
import {
  Container,
  Content,
  Child,
  HeaderButton,
  Logo,
  ButtonSair,
} from './styles';
import { usePageContext } from '~/hooks/usePage';
import { IconMenu } from '~/assets/Icons';
import logoAd from '~/assets/Images/logoBranco.png';
import { logout } from '~/store/modules/auth/actions';

const Header: React.FC = () => {
  const { setCollapseMenu, collapseMenu, setFocus, fixed } = usePageContext();
  const dispatch = useDispatch();

  window.onclick = (e: any) => {
    const { target } = e;
    const alvMenu = target.classList.contains('menu');
    const paiMenu = target.closest('.menu');
    const alvInputSearch = target.classList.contains('inputSearch');
    const paiInputSearch = target.closest('.inputSearch');
    if (!alvInputSearch && !paiInputSearch) {
      setFocus(false);
    }
    if (collapseMenu && !alvMenu && !paiMenu) {
      setCollapseMenu(false);
    }
    return null;
  };

  return (
    <Child>
      <SideMenu />
      <Container>
        <Content>
          <HeaderButton
            state={collapseMenu}
            notification={false}
            className="menu"
            onClick={() => setCollapseMenu(true)}
          >
            <IconMenu />
          </HeaderButton>
        </Content>
        <Content>
          <Logo fix={fixed} src={logoAd} alt="Logo-ADPB" />
        </Content>
        <Content>
          <ButtonSair
            onClick={() => {
              sessionStorage.clear();
              dispatch(logout());
            }}
          >
            Sair
            <IoLogOutOutline color="#FF0000" />
          </ButtonSair>
        </Content>
      </Container>
    </Child>
  );
};

export default Header;
