import { Transfer } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import departamentService from '~/services/departamentService';
import profileService from '~/services/profileService';

import { generateRequiredInputValues, isEmpty, validateForm } from '~/utils';

import { Input, Loading, ModalDefault, Page, PageHeader } from '~/components';
import { Container, Content, TransferContainer } from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const EditProfile: React.FC = () => {
  const history = useHistory();
  const { type } = useParams<{ type: string }>();
  const [loading, setLoading] = useState(false);
  const [typePage, setTypePage] = useState(false);
  const [listPermission, setListPermission] = useState([]);
  const [listFunctionalities, setListFunctionalities] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalSucess, setIsVisibleModalSucess] = useState(false);

  const [targetKeys, setTargetKeys] = useState<any>([]);
  const [selectedKeys, setSelectedKeys] = useState<any>([]);

  const [targetKeysDepartament, setTargetKeysDepartament] = useState<any>([]);
  const [selectedKeysDepartament, setSelectedKeysDepartament] = useState<any>(
    [],
  );

  const [targetKeysFunctionalities, setTargetKeysFunctionalities] =
    useState<any>([]);
  const [selectedKeysFunctionalities, setSelectedKeysFunctionalities] =
    useState<any>([]);

  const stateSchema = {
    ...generateRequiredInputValues(['name', 'description']),
  };

  const [inputValue, setInputValue] = useState<typeInput>(stateSchema);

  const loadPermission = async (Functionalities: string[]) => {
    if (Functionalities.length > 0) {
      const responsePermission = await profileService.listPermission(
        Functionalities,
      );
      setListPermission(responsePermission);
      setSelectedKeys(null);
    } else {
      setListPermission([]);
    }
  };

  useEffect(() => {
    if (type === 'create') {
      setTypePage(true);
    } else {
      const loadSector = async () => {
        setLoading(true);
        const arrayFunctionalities: string[] = [];
        const response = await profileService.getProfileById(type);
        setInputValue((prevState: any) => ({
          ...prevState,
          name: {
            ...prevState.name,
            value: response.name,
          },
          description: {
            ...prevState.description,
            value: response.description,
          },
        }));
        await response.functionalities.forEach((element: { id: number }) => {
          arrayFunctionalities.push(element.id.toString());
          return setTargetKeysFunctionalities((prevState: any) => [
            ...prevState,
            element.id.toString(),
          ]);
        });
        loadPermission(arrayFunctionalities);

        response.permissions.forEach((element: { id: number }) => {
          return setTargetKeys((prevState: any) => [
            ...prevState,
            element.id.toString(),
          ]);
        });
        response.departaments.forEach((element: { id: number }) => {
          return setTargetKeysDepartament((prevState: any) => [
            ...prevState,
            element.id.toString(),
          ]);
        });
        setLoading(false);
      };
      loadSector();
    }
  }, [type]);

  useEffect(() => {
    const load = async () => {
      const responseDepartament = await departamentService.listAllDepartament({
        limit: 200,
        page: 1,
      });
      setListDepartment(responseDepartament.result);
      const responseFunctionalities =
        await profileService.listFunctionalities();
      setListFunctionalities(responseFunctionalities.result);
    };
    load();
  }, []);

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputValue[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputValue(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputValue[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputValue);
    let hasError = false;

    Object.entries(inputValue).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    Object.keys(inputValue).forEach(inputName => {
      if (inputValue[inputName].error) {
        hasError = true;
      }
    });
    return isEmpty(inputsWithError) && !hasError;
  };

  const toSave = async () => {
    if (typePage) {
      if (isFormValid()) {
        try {
          const request = await profileService.createProfile({
            name: inputValue.name.value,
            description: inputValue.description.value,
          });
          await profileService.addPermissionsToProfile(request.id, targetKeys);
          await profileService.addDepartamentsToProfile(
            request.id,
            targetKeysDepartament,
          );
          await profileService.addFunctionalitiesToProfile(
            request.id,
            targetKeysFunctionalities,
          );
          setIsVisibleModalSucess(true);
        } catch {
          setIsVisibleModalErro(true);
        }
      } else {
        setIsVisibleModalErro(true);
      }
    }

    if (!typePage) {
      if (isFormValid()) {
        try {
          const request = await profileService.putProfile({
            id: type,
            name: inputValue.name.value,
            description: inputValue.description.value,
          });
          await profileService.putPermissionsInProfile(request.id, targetKeys);
          await profileService.putDepartamentsInProfile(
            request.id,
            targetKeysDepartament,
          );
          await profileService.putFunctionalitiesInProfile(
            request.id,
            targetKeysFunctionalities,
          );
          setIsVisibleModalSucess(true);
        } catch {
          setIsVisibleModalErro(true);
        }
      } else {
        setIsVisibleModalErro(true);
      }
    }
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  const onChange = (nextTargetKeys: any) => {
    setTargetKeys(nextTargetKeys);
  };

  const onChangeDepartament = (nextTargetKeys: any) => {
    setTargetKeysDepartament(nextTargetKeys);
  };
  const onChangeFunctionalities = (nextTargetKeys: any) => {
    loadPermission(nextTargetKeys);
    setTargetKeysFunctionalities(nextTargetKeys);
  };
  const onSelectChange = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectChangeDepartament = (
    sourceSelectedKeys: any,
    targetSelectedKeys: any,
  ) => {
    setSelectedKeysDepartament([...sourceSelectedKeys, ...targetSelectedKeys]);
  };
  const onSelectChangeFunctionalities = (
    sourceSelectedKeys: any,
    targetSelectedKeys: any,
  ) => {
    setSelectedKeysFunctionalities([
      ...sourceSelectedKeys,
      ...targetSelectedKeys,
    ]);
  };

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Não foi possível salvar!
        </ModalDefault>
      )}
      {isVisibleModalSucess && (
        <ModalDefault success onClick={() => history.push('/perfis')}>
          Cadastro finalizado com sucesso!
        </ModalDefault>
      )}
      <Page>
        <PageHeader
          title="Criar Perfil"
          button="Salvar"
          onClick={() => toSave()}
        />
        <Container>
          <Content>
            <Input
              label="Nome"
              requiredLabel
              value={inputValue.name.value}
              onChange={e => handleInput(e.target.value, 'name')}
              error={inputValue.name.error}
            />

            <Input
              label="Descrição"
              requiredLabel
              value={inputValue.description.value}
              onChange={e => handleInput(e.target.value, 'description')}
              error={inputValue.description.error}
            />

            <TransferContainer>
              <b>Selecionar Funcionalidades</b>
              <Transfer
                dataSource={listFunctionalities}
                targetKeys={targetKeysFunctionalities}
                selectedKeys={selectedKeysFunctionalities}
                onChange={onChangeFunctionalities}
                onSelectChange={onSelectChangeFunctionalities}
                render={(item: any) => item.name}
              />
            </TransferContainer>
            <TransferContainer>
              <b>Selecionar Permissões</b>
              <Transfer
                dataSource={listPermission}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                render={(item: any) => item.name}
              />
            </TransferContainer>
            <TransferContainer>
              <b>Selecionar Departamentos</b>
              <Transfer
                dataSource={listDepartment}
                targetKeys={targetKeysDepartament}
                selectedKeys={selectedKeysDepartament}
                onChange={onChangeDepartament}
                onSelectChange={onSelectChangeDepartament}
                render={(item: any) => item.initials}
              />
            </TransferContainer>
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default EditProfile;
