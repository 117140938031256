import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 3%;
`;

export const Content = styled.div`
  padding: 3%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  width: 100%;
  height: fit-content;
`;

export const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div {
    width: 49% !important;
    div {
      width: 100% !important;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InputBox = styled.div`
  width: 100%;
`;

export const TransferContainer = styled.div`

  .ant-transfer-list{
    width: 100%;
  }

  b{
    margin: 10px 0;
    display: block;
  }

  div {
    display: flex;
    justify-content: center;
`;
