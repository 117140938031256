import api from './api';

// interface IListSectors {
//   limit?: number;
//   page?: number;
// }
// interface IEditSector {
//   id: number | string;
//   name: string;
//   areaId: number | string;
// }
interface ILogin {
  email: string;
  password: string;
}

class SectorService {
  async login({ email, password }: ILogin): Promise<any> {
    const response = await api.post(`/sessions`, {
      email,
      password,
    });

    return response.data;
  }

  async showMenu(): Promise<any> {
    const response = await api.get(`/functionalities/menu`);

    return response.data;
  }
}

export default new SectorService();
