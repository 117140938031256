import React from 'react';
import { RiLockPasswordFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';

import { SwitchIcon } from '~/components';

import { Container, LinkMenu } from './styles';

const ButtonDashboard: React.FC = () => {
  const menu = useSelector((state: any) => state.auth.data.menu);

  const childSessions = (item: any) => {
    if (item.children.length > 0) {
      return (
        <>
          {item.children.map((child: any) => {
            if (child.children.length > 0) {
              return null;
            }
            return (
              <LinkMenu
                to={child.link}
                key={`${child.id}-linkmenu`}
                className="itensMenu"
              >
                <SwitchIcon name={child.icon} color="#323232" />
                {child.name}
              </LinkMenu>
            );
          })}
        </>
      );
    }
    return null;
  };

  return (
    <Container key="container-container">
      {menu.map((item: any) => {
        return childSessions(item);
      })}
      <LinkMenu to="/senha" key="AlterarASenha-linkmenu" className="itensMenu">
        <RiLockPasswordFill />
        Alterar a senha
      </LinkMenu>
    </Container>
  );
};

export default ButtonDashboard;
