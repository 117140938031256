import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 3%;
`;

export const Content = styled.div`
  padding: 3%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  width: 100%;
  height: fit-content;

  #select,
  input,
  label,
  p {
    margin: 0 10px;
  }
`;

export const Title = styled.b`
  display: block;
  margin: 20px 10px 0 10px;
  font-size: 20px;
`;

export const ContainerBox = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InputBox = styled.div`
  width: 100%;
`;

export const ContentLeader = styled.div`
  padding: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  width: calc(100% - 20px);
  /* display: flex;
  flex-direction: column; */
  margin: 10px;

  #select,
  input,
  label,
  p {
    margin: 0 10px;
  }

  table {
    width: 100%;
    th {
      text-align: start;
    }
    margin-top: 15px;
  }

  span {
    display: flex;
    justify-content: space-between;
  }

  button {
    cursor: pointer;
    color: rgb(72, 140, 250);
    height: min-content;
    display: flex;
    align-items: center;
  }
`;

export const Label = styled.p`
  display: block;
  margin: 10px !important;
`;

export const ViewMore = styled.button`
  text-decoration: underline;
  color: rgb(72, 140, 250);
  margin: 10px;
`;
