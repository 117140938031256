/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// import { useDropzone } from 'react-dropzone';
import { Input, Loading, ModalDefault, Page, PageHeader } from '~/components';
import DepartamentService from '~/services/departamentService';
import { generateRequiredInputValues, isEmpty, validateForm } from '~/utils';
import {
  ColorInput,
  Container,
  // ContainerUpload,
  Content,
  // ContentUpload,
  // ImgZone,
  InputBox,
} from './styles';
// import ImgDefault from '~/assets/Images/ImgDefault.png';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const EditDepartament: React.FC = () => {
  const history = useHistory();
  const { type } = useParams<{ type: string }>();
  const [loading, setLoading] = useState(false);
  const [typePage, setTypePage] = useState(false);
  // const [imgArray, setImgArray] = useState([]);
  // const [imgUrl, setImgUrl] = useState('');
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalSucess, setIsVisibleModalSucess] = useState(false);

  const stateSchema = {
    ...generateRequiredInputValues(['name', 'color', 'initials']),
  };

  const [inputValue, setInputValue] = useState<typeInput>(stateSchema);

  // function getBase64(img: any, callback: any) {
  //   if (img.type === 'image/jpeg' || img.type === 'image/png') {
  //     const reader = new FileReader();
  //     reader.addEventListener('load', () => callback(reader.result));
  //     reader.readAsDataURL(img);
  //   }
  // }

  // const onDrop = useCallback(acceptedFiles => {
  //   if (
  //     acceptedFiles[0].type === 'image/jpeg' ||
  //     acceptedFiles[0].type === 'image/png'
  //   ) {
  //     // setImgArray(acceptedFiles);
  //     getBase64(acceptedFiles[0], (imageUrl: any) => {
  //       setImgUrl(imageUrl);
  //     });
  //   }
  // }, []);

  // const { getRootProps, getInputProps, isDragActive, isDragReject } =
  //   useDropzone({
  //     onDrop,
  //   });

  // function content() {
  //   if (imgUrl) {
  //     return <ImgZone src={imgUrl} alt="Foto do cliente" />;
  //   }
  //   return (
  //     <ContentUpload>
  //       <img src={ImgDefault} alt="sem imagem" />
  //       <p>
  //         Clique aqui para adicionar uma imagem do seu computador ou arraste-a
  //         para esse campo.
  //       </p>
  //     </ContentUpload>
  //   );
  // }

  useEffect(() => {
    if (type === 'create') {
      setTypePage(true);
    } else {
      const loadDepartament = async () => {
        setLoading(true);
        const response = await DepartamentService.getDepartamentById(type);
        setInputValue((prevState: any) => ({
          ...prevState,
          name: {
            ...prevState.name,
            value: response.name,
          },
          color: {
            ...prevState.color,
            value: response.color,
          },
          initials: {
            ...prevState.initials,
            value: response.initials,
          },
        }));
        setLoading(false);
      };
      loadDepartament();
    }
  }, [type]);

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputValue[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputValue(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputValue[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputValue);
    let hasError = false;

    Object.entries(inputValue).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    Object.keys(inputValue).forEach(inputName => {
      if (inputValue[inputName].error) {
        hasError = true;
      }
    });
    return isEmpty(inputsWithError) && !hasError;
  };

  const toSave = async () => {
    if (typePage) {
      if (isFormValid()) {
        try {
          await DepartamentService.CreateDepartament({
            name: inputValue.name.value,
            initials: inputValue.initials.value,
            color: inputValue.color.value,
          });
          setIsVisibleModalSucess(true);
        } catch {
          setIsVisibleModalErro(true);
        }
      } else {
        setIsVisibleModalErro(true);
      }
    } else if (isFormValid()) {
      try {
        await DepartamentService.editDepartamentById({
          id: type,
          name: inputValue.name.value,
          initials: inputValue.initials.value,
          color: inputValue.color.value,
        });
        setIsVisibleModalSucess(true);
      } catch {
        setIsVisibleModalErro(true);
      }
    } else {
      setIsVisibleModalErro(true);
    }
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Não foi possível salvar!
        </ModalDefault>
      )}
      {isVisibleModalSucess && (
        <ModalDefault success onClick={() => history.push('/departamentos')}>
          Cadastro finalizado com sucesso!
        </ModalDefault>
      )}
      <Page>
        <PageHeader
          title={typePage ? 'Criar Departamento' : 'Editar Departamento'}
          button="Salvar"
          onClick={() => toSave()}
        />
        <Container>
          <Content>
            {/* <ContainerUpload>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragReject && (
                  <ContentUpload>
                    <p>Arquivo não suportado, tente um arquivo .jpg ou .png</p>
                  </ContentUpload>
                )}
                {isDragActive ? (
                  <ContentUpload>
                    <p>Solte os arquivos aqui ... </p>
                  </ContentUpload>
                ) : (
                  content()
                )}
              </div>
            </ContainerUpload> */}
            <InputBox>
              <Input
                label="Nome"
                requiredLabel
                value={inputValue.name.value}
                onChange={e => handleInput(e.target.value, 'name')}
                error={inputValue.name.error}
              />

              <Input
                label="Sigla"
                requiredLabel
                value={inputValue.initials.value}
                onChange={e => {
                  handleInput(e.target.value, 'initials');
                }}
                error={inputValue.initials.error}
              />
              <ColorInput>
                <Input
                  label="Cor"
                  type="color"
                  requiredLabel
                  value={inputValue.color.value}
                  onChange={e => {
                    handleInput(e.target.value, 'color');
                  }}
                  error={inputValue.color.error}
                />
              </ColorInput>
            </InputBox>
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default EditDepartament;
