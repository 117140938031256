import api from './api';

interface IListSectors {
  limit?: number;
  page?: number;
}
interface IEditSector {
  id: number | string;
  name: string;
  areaId: number | string;
}
interface ICreateSector {
  name: string;
  areaId: number | string;
}

class SectorService {
  async listSector({ limit = 200, page = 1 }: IListSectors): Promise<any> {
    const response = await api.get(`/sectors`, {
      params: {
        limit,
        page,
      },
    });

    return response.data;
  }

  async getSectorById(id: number | string): Promise<any> {
    const response = await api.get(`/sectors/${id}`);

    return response.data;
  }

  async deleteSectorById(id: number | string): Promise<any> {
    const response = await api.delete(`/sectors/${id}`);

    return response.data;
  }

  async editSectorById({ id, name, areaId }: IEditSector): Promise<any> {
    const response = await api.put(`/sectors/${id}`, {
      name,
      area_id: areaId,
    });

    return response.data;
  }

  async CreateSector({ name, areaId }: ICreateSector): Promise<any> {
    const response = await api.post(`/sectors`, {
      name,
      area_id: areaId,
    });

    return response.data;
  }
}

export default new SectorService();
