import api from './api';

class FrequencyService {
  async listAllClasses(): Promise<any> {
    const response = await api.get(`/classes`);

    return response.data;
  }

  async listMyClasses(id: number | string): Promise<any> {
    const response = await api.get(`/bs/classes/churchs/${id}`);

    return response.data;
  }

  async EditClasses(data: number[], id: number | string): Promise<any> {
    const response = await api.patch(`/bs/classes/churchs/${id}`, {
      classes_ids: data,
    });

    return response.data;
  }

  async ApproveRequestUpdate(id: number | string): Promise<any> {
    const response = await api.patch(
      `/bs/update-classes-request/${id}/approve`,
    );

    return response.data;
  }

  async ShowFrequency(id: number | string): Promise<any> {
    const response = await api.get(`/bs/frequencies/${id}`);

    return response.data;
  }
}

export default new FrequencyService();
