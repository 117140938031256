import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 3%;
`;

export const Content = styled.div`
  padding: 3%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  width: 100%;
  height: fit-content;

  span {
    padding-top: 15px;
    display: block;
    font-weight: 700;
    button {
      margin-top: 16px;
    }
  }
`;

export const DisplayDate = styled.div`
  padding: 1%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 6px;
  width: 100%;
  height: fit-content;
  margin-top: 10px;
  font-size: 12px;
`;

export const ColorInput = styled.span`
  padding: 0 10px;
  input {
    padding: 0 !important;
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  margin: 3%;
  flex-direction: column;

  span {
    font-size: 22px;
    font-weight: 700;
  }

  button {
    margin-top: 15px;
  }
`;
export const HeaderModal = styled.div`
  display: flex;
  aling-items: center;
  justify-content: space-between;

  svg {
    color: #488cfa;
  }

  button {
    margin-top: 0;
  }
`;
