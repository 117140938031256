import React, { useState } from 'react';
import { IconClose } from '~/assets/Icons';
import { Button, Input, Modal } from '~/components';
import leadersService from '~/services/leadersService';
import { CloseButton, ContainerInput, Title } from './styles';

interface IProps {
  data: any;
  onClickClose: () => void;
  onClickSave: () => void;
  churchId: number;
}

const ModalEditLeader: React.FC<IProps> = ({
  data,
  onClickClose,
  onClickSave,
  churchId,
}: IProps) => {
  const [finishDate, setFinishDate] = useState('');

  const toSave = async () => {
    await leadersService.removeLeaderInChurch({
      leaderId: data.leader.id,
      begined: finishDate,
      churchId,
    });
    onClickClose();
    onClickSave();
  };

  return (
    <Modal>
      <>
        <CloseButton onClick={() => onClickClose()}>
          <IconClose />
        </CloseButton>
        <Title>Editar dirigente da congregação</Title>
        <p>Dirigente: {data.leader.name}</p>
        <ContainerInput>
          <Input
            type="date"
            value={data.begined_at.substring(0, 10)}
            label="Início"
            disabled
          />
          <Input
            type="date"
            onChange={e => setFinishDate(e.target.value)}
            label="Término"
          />
        </ContainerInput>
        <Button
          onClick={() => {
            toSave();
          }}
        >
          Salvar
        </Button>
      </>
    </Modal>
  );
};

export default ModalEditLeader;
