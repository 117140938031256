/* eslint-disable no-alert */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Header, Loading } from '~/components';
import { generateDefaultInputValues, isEmpty, validateForm } from '~/utils';
import ModalDefalut from '~/components/ModalDefault';
import frequencyService from '~/services/frequencyService';
import { Container, Content, Question, Title, Select } from './styles';

interface IPropsInput {
  isValid: boolean;
  value: '0' | '1';
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const ChurchRegistration: React.FC = () => {
  const state: string[] = [];
  const { type } = useParams<{ type: string }>();
  const history = useHistory();

  const stateSchema = {
    ...generateDefaultInputValues([
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
    ]),
  };

  const [inputState, setInputState] = useState<typeInput>(stateSchema);
  const [listClasses, setListClasses] = useState<any[]>([]);
  const [isVisibleModalSucess, setIsVisibleModalSucess] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleInput = (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState![inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  useEffect(() => {
    const loadClasses = async () => {
      setLoading(true);
      try {
        const response = await frequencyService.listAllClasses();
        setListClasses(response.result);

        response.result.forEach((item: any) => {
          state.push(item.id);
        });
        // const stateSchema = {
        //   ...generateDefaultInputValues(state),
        // };
        // setInputState(stateSchema);
      } catch {
        alert('erro loadList');
      }
    };

    // const loadMyClasses = async () => {
    //   try {
    //     const response = await frequencyService.listMyClasses();
    //     response.forEach((allInput: any) => {
    //       handleInput('1', allInput.id);
    //     });
    //   } catch {
    //     alert('erroLoadValeu');
    //   }
    //   setLoading(false);
    // };
    const loadFunctions = async () => {
      await loadClasses();
      // await loadMyClasses();
    };
    loadFunctions();
  }, []);

  useEffect(() => {
    const loadMyClasses = async () => {
      const response = await frequencyService.listMyClasses(type);

      response.classes.forEach((allInput: any) => {
        handleInput('1', allInput.id);
      });
    };

    loadMyClasses();
    setLoading(false);
  }, []);

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const validAll = async () => {
    const value: number[] = [];

    Object.entries(inputState).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    const create = async () => {
      if (isFormValid()) {
        try {
          Object.entries(inputState).forEach(inputValue => {
            if (inputValue[1].value === '1') {
              value.push(parseFloat(inputValue[0]));
            }
          });
          await frequencyService.EditClasses(value, type);
          setIsVisibleModalSucess(true);
        } catch {
          setIsVisibleModalErro(true);
        }
      } else {
        setIsVisibleModalErro(true);
      }
    };
    create();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {isVisibleModalSucess && (
        <ModalDefalut success onClick={() => history.push('/igrejas-ebd')}>
          Cadastro atualizado com sucesso!
        </ModalDefalut>
      )}
      {isVisibleModalErro && (
        <ModalDefalut
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Ops... algo deu errado!
        </ModalDefalut>
      )}
      <Container>
        <Header />
        <Content>
          <Title>Adicionar salas</Title>
          {/* <SubTitle>Valentina 1</SubTitle> */}
          {/* <SubTitle position={1}>Adicionar salas:</SubTitle> */}
          {listClasses.map(item => {
            return (
              <Question key={item.id}>
                <b>{item.name}</b>
                <Select
                  onChange={(e: any) => handleInput(e.target.value, item.id)}
                  value={inputState[item.id]?.value}
                >
                  <option value="0">Não</option>
                  <option value="1">Sim</option>
                </Select>
              </Question>
            );
          })}

          <Button onClick={validAll}>Salvar</Button>
        </Content>
      </Container>
    </>
  );
};

export default ChurchRegistration;
