import React, { useState } from 'react';
import { Page } from '~/components';
import ManagersAssiduity from './ManagersAssiduity';
import CongregationFrequency from './CongregationFrequency';
import ReportsSent from './ReportSent';
// import { BtMenu, Header } from './styles';

const EBDV2: React.FC = () => {
  const [tabs] = useState({
    Managers: true,
    Congegation: false,
    ReportsSent: false,
  });
  return (
    <>
      <Page>
        {/* <Header>
          <BtMenu
            onClick={() =>
              setTabs({
                Managers: true,
                Congegation: false,
                ReportsSent: false,
              })
            }
          >
            Assiduidade de dirigentes
          </BtMenu>
          <BtMenu
            onClick={() =>
              setTabs({
                Managers: false,
                Congegation: true,
                ReportsSent: false,
              })
            }
          >
            Frequência da congregação
          </BtMenu>
          {/* <BtMenu
            onClick={() =>
              setTabs({
                Managers: false,
                Congegation: false,
                ReportsSent: true,
              })
            }
          >
            {'   '}Relatórios enviados{'  '}
          </BtMenu> 
        </Header> */}
        {tabs.ReportsSent && <ReportsSent />}
        {tabs.Managers && <ManagersAssiduity />}
        {tabs.Congegation && <CongregationFrequency />}
      </Page>
    </>
  );
};

export default EBDV2;
