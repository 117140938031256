import { useHistory } from 'react-router-dom';
import { usePageContext } from '~/hooks/usePage';
import { Header } from '~/components';

import { Content, Container, BackButton } from './styles';

interface IProps {
  children: React.ReactNode;
  disabledBack?: boolean;
}

const defaultProps = {
  disabledBack: false,
};

const Page: React.FC<IProps> = ({ children, disabledBack }: IProps) => {
  const { fixed } = usePageContext();
  const history = useHistory();
  const textButton = '<- Voltar';

  return (
    <Container>
      <Header />
      <Content fix={fixed}>
        <>
          {!disabledBack && (
            <BackButton onClick={() => history.goBack()}>
              {textButton}
            </BackButton>
          )}
          {children}
        </>
      </Content>
    </Container>
  );
};

Page.defaultProps = defaultProps;

export default Page;
