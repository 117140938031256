import React from 'react';
import {
  MdHelpOutline,
  MdOutlineGroup,
  MdOutlineSpaceDashboard,
  MdSchool,
} from 'react-icons/md';
import {
  FaChurch,
  FaHome,
  FaThLarge,
  FaCalendarAlt,
  FaUserAlt,
  FaUsersCog,
  FaNetworkWired,
} from 'react-icons/fa';

interface IProps {
  name: string;
  color?: string;
}

const defaultProps = {
  color: '#323232',
};

const SwitchIcon: React.FC<IProps> = ({ name, color }: IProps) => {
  switch (name) {
    case 'schedules':
      return <FaCalendarAlt color={color} />;
    case 'reports':
      return <FaThLarge color={color} />;
    case 'churchs':
      return <FaChurch color={color} />;
    case 'users':
      return <FaUserAlt color={color} />;
    case 'profiles':
      return <FaUsersCog color={color} />;
    case 'home':
      return <FaHome color={color} />;
    case 'areas':
      return <MdOutlineSpaceDashboard color={color} />;
    case 'departaments':
      return <FaNetworkWired color={color} />;
    case 'sectors':
      return <MdOutlineGroup color={color} />;
    case 'bs':
      return <MdSchool color={color} />;
    default:
      return <MdHelpOutline color={color} />;
  }
};

SwitchIcon.defaultProps = defaultProps;

export default SwitchIcon;
