import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
  max-width: 600px;
  width: 100%;
  height: 100%;
`;

export const Header = styled.header`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  h2 {
    margin: 0px;
    color: #323232;
    font-size: 1.2rem;
  }
`;

export const Content = styled.div`
  padding: 10px 15px;
  overflow-x: auto;
  max-height: 600px;
  height: 100%;
`;
