import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  align-items: center;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: 15px 0px;
  width: 90%;
  padding: 15px;

  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;

  font-size: 1rem;

  button {
    margin-top: 15px;
  }
  span {
    margin: 20px 0;
    div {
      width: 100%;
    }
    button {
      margin-top: 0px;
    }
  }
`;

export const Question = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 20px 0;

  button {
    margin: 0px;
  }
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 20px;
  margin-bottom: 0px;
`;
export const SubTitle = styled('h6')<{ position?: 1 }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${props => props.position && `justify-content: start;`}

  margin-bottom: 20px;
`;

export const Select = styled.select`
  border-radius: 5px;

  height: 45px;
  padding: 15px;

  border: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
  background: #fcfcfc;
  color: rgba(0, 0, 0, 0.5);
`;
