import { format } from 'date-fns';
import React from 'react';
import { IconClose } from '~/assets/Icons';
import { Modal, TablePage } from '~/components';
import { Content, TitleModal } from './styles';

interface IProps {
  data: any;
  onClose: () => void;
}

const ModalDetails: React.FC<IProps> = ({ data, onClose }: IProps) => {
  const coluna = [
    {
      title: 'Congregação',
      dataIndex: 'church',
      key: 'date',
      render: (e: any) => e.name,
    },
    {
      title: 'De',
      dataIndex: 'begined_at',
      key: 'member',
      render: (e: any) => format(new Date(e), `dd/MM/yyyy`),
    },

    {
      title: 'Até',
      dataIndex: 'ended_at',
      key: 'member',
      render: (e: any) => (e ? format(new Date(e), `dd/MM/yyyy`) : '-'),
    },
  ];

  return (
    <Modal width="1000px">
      <>
        <TitleModal>
          <button type="button" onClick={onClose}>
            <IconClose />
          </button>
        </TitleModal>
        <Content>
          <b>Congregações que o dirigente passou</b>
          <TablePage dataSource={data.churchLeaders} columns={coluna} />
        </Content>
      </>
    </Modal>
  );
};

export default ModalDetails;
