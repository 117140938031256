import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { IconDelete, IconEdit } from '~/assets/Icons';
import {
  Page,
  TablePage,
  PageHeader,
  ModalDelete,
  Loading,
} from '~/components';
import sectorService from '~/services/sectorService';
import userService from '~/services/userService';
import { Content, Picture } from './styles';
import ImgDefault from '~/assets/Images/ImgDefault.png';

const Users: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [limit] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [handleIdDelete, setHandleIdDelete] = useState(0);

  const history = useHistory();

  const loadUsers = async ({ page }: { limit: number; page: number }) => {
    setLoading(true);
    const res = await userService.listUsers({ page });

    setDataSource(res.result);
    setPage(res.info.page);
    setPages(res.info.pages);
    setLoading(false);
  };

  useEffect(() => {
    loadUsers({ limit, page });
  }, [limit, page]);

  const columns = [
    {
      key: 'file',
      dataIndex: 'file',
      width: '40px',
      render: (e: any) => (
        <>
          <Picture src={e?.path || ImgDefault} />
        </>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Perfil',
      dataIndex: 'profile',
      key: 'profile',
      render: (e: { name: string }) => e?.name,
    },
    // {
    //   title: 'Ações',
    //   key: 'action',
    //   width: '100px',
    //   dataIndex: 'id',
    //   render: (e: number) => (
    //     <>
    //       <BtTrash
    //         onClick={() => {
    //           setHandleIdDelete(e);
    //           setIsVisibleModalDelete(true);
    //         }}
    //       >
    //         <IconDelete />
    //       </BtTrash>
    //       <BtEdit onClick={() => history.push(`/setor/${e}`)}>
    //         <IconEdit />
    //       </BtEdit>
    //     </>
    //   ),
    // },
  ];

  const deleteUser = async () => {
    setLoading(true);
    await sectorService.deleteSectorById(handleIdDelete);
    setIsVisibleModalDelete(false);
    loadUsers({ limit, page });
    setLoading(false);
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalDelete && (
        <ModalDelete
          text="usuário"
          onClickCancel={() => {
            setIsVisibleModalDelete(false);
            setHandleIdDelete(0);
          }}
          onClickDelete={() => deleteUser()}
        />
      )}
      <Page>
        <PageHeader
          title="Usuários"
          button="+ Adicionar novo usuário"
          onClick={() => history.push(`/usuario/create`)}
        />
        <Content>
          <TablePage
            columns={columns}
            dataSource={dataSource}
            pagination={{
              current: page,
              showSizeChanger: false,
              pageSize: limit,
              total: pages * limit,
              onChange: e => {
                setPage(e);
              },
            }}
            rowKey="id"
          />
        </Content>
      </Page>
    </>
  );
};

export default Users;
