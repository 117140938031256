import api from './api';

interface IProfileCreate {
  name: string;
  description: string;
}

interface IProfile {
  id: number | string;
  name: string;
  description: string;
}

interface IListProfile {
  limit?: number;
  page?: number;
}

class UserService {
  async listProfile({ limit, page }: IListProfile): Promise<any> {
    const response = await api.get(`/profiles`, {
      params: {
        limit,
        page,
      },
    });

    return response.data;
  }

  async deleteProfile(id: number | string): Promise<any> {
    const response = await api.delete(`/profiles/${id}`);

    return response.data;
  }

  async getProfileById(id: number | string): Promise<any> {
    const response = await api.get(`/profiles/${id}`);

    return response.data;
  }

  async putProfile({ id, name, description }: IProfile): Promise<any> {
    const response = await api.put(`/profiles/${id}`, {
      name,
      description,
    });

    return response.data;
  }

  async createProfile({ name, description }: IProfileCreate): Promise<any> {
    const response = await api.post(`/profiles`, {
      name,
      description,
    });

    return response.data;
  }

  async listPermission(functionalities: string[]): Promise<any> {
    const response = await api.get(`/permissions/functionalities`, {
      params: {
        functionalities_ids: functionalities,
      },
    });

    return response.data;
  }

  async listFunctionalities(): Promise<any> {
    const response = await api.get(`/functionalities`);

    return response.data;
  }

  async addDepartamentsToProfile(id: number | string, ids: any): Promise<any> {
    const response = await api.post(`/profiles/${id}/departaments`, {
      departaments_ids: ids,
    });

    return response.data;
  }

  async addFunctionalitiesToProfile(
    id: number | string,
    ids: any,
  ): Promise<any> {
    const response = await api.post(`/profiles/${id}/functionalities`, {
      functionalities_ids: ids,
    });

    return response.data;
  }

  async addPermissionsToProfile(id: number | string, ids: any): Promise<any> {
    const response = await api.post(`/profiles/${id}/permissions`, {
      permissions_ids: ids,
    });

    return response.data;
  }

  async putDepartamentsInProfile(id: string | number, ids: any): Promise<any> {
    const response = await api.patch(`/profiles/${id}/departaments`, {
      departaments_ids: ids,
    });

    return response.data;
  }

  async putPermissionsInProfile(id: string | number, ids: any): Promise<any> {
    const response = await api.patch(`/profiles/${id}/permissions`, {
      permissions_ids: ids,
    });

    return response.data;
  }

  async putFunctionalitiesInProfile(
    id: string | number,
    ids: any,
  ): Promise<any> {
    const response = await api.patch(`/profiles/${id}/functionalities`, {
      functionalities_ids: ids,
    });

    return response.data;
  }
  // async removeDepartamentsToProfile(
  //   id: number | string,
  //   ids: any,
  // ): Promise<any> {
  //   const response = await api.delete(`/profiles/${id}/departaments`, {
  //     departaments_ids: ids,
  //   });

  //   return response.data;
  // }
}

export default new UserService();
