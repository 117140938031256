import styled from 'styled-components';

export const TitleModal = styled.div`
  svg {
    color: #488cfa;
  }
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const Content = styled.div`
  margin-top: 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;

  b {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
  }

  span {
    margin-top: 15px;
    display: block;
    width: 100%;
  }
`;
