import styled from 'styled-components';

export const Title = styled.b`
  display: block;
  font-size: 20px;
  margin-bottom: 5px;
`;

export const Label = styled.p`
  display: block;
  margin: 10px !important;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #df0a24;
`;

export const ContainerInput = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  div {
    width: 48% !important;
    div {
      width: 100% !important;
    }
  }
`;
