import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IconClose } from '~/assets/Icons';
import {
  Button,
  Input,
  InputSearch,
  Loading,
  Modal,
  ModalDefault,
  Page,
  PageHeader,
} from '~/components';
import churchService from '~/services/churchService';
import departamentService from '~/services/departamentService';
import eventsService from '~/services/eventsService';
import { generateRequiredInputValues, isEmpty, validateForm } from '~/utils';
import {
  Container,
  ContainerModal,
  Content,
  DisplayDate,
  HeaderModal,
} from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const EditEvents: React.FC = () => {
  const history = useHistory();
  const { type } = useParams<{ type: string }>();
  const [dates, setDates] = useState([]);
  const [currentChurchName, setCurrentChurchName] = useState('');
  const [listChurch, setListChurch] = useState([]);
  const [inputChurch, setInputChurch] = useState('');

  const [currentDepartamentName, setCurrentDepartamentName] = useState('');
  const [listDepartament, setListDepartament] = useState([]);
  const [inputDepartament, setInputDepartament] = useState('');

  const [loading, setLoading] = useState(false);
  const [typePage, setTypePage] = useState(false);
  const [isVisibleModalDate, setIsVisibleModalDate] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalSucess, setIsVisibleModalSucess] = useState(false);

  const stateSchema = {
    ...generateRequiredInputValues([
      'name',
      'description',
      'churchId',
      'departamentId',
    ]),
  };

  const stateSchemaDate = {
    ...generateRequiredInputValues(['date', 'startTime', 'endTime']),
  };

  const [inputValue, setInputValue] = useState<typeInput>(stateSchema);
  const [inputValueDate, setInputValueDate] =
    useState<typeInput>(stateSchemaDate);

  useEffect(() => {
    if (type === 'create') {
      setTypePage(true);
    } else {
      const loadEvents = async () => {
        setLoading(true);
        const response = await eventsService.getEventById(type);
        setInputValue((prevState: any) => ({
          ...prevState,
          name: {
            ...prevState.name,
            value: response.name,
          },
          departamentId: {
            ...prevState.departamentId,
            value: response.departament_Id,
          },
          churchId: {
            ...prevState.churchId,
            value: response.church_Id,
          },
        }));
        setCurrentChurchName(response.church.name);
        setCurrentDepartamentName(response.departament.name);
        setLoading(false);
      };
      loadEvents();
    }
  }, [type]);

  useEffect(() => {
    const loadList = async () => {
      setLoading(true);
      const responseChurch = await churchService.listChurch({
        // limit: 200,
        page: 1,
      });
      setListChurch(responseChurch.result);
      const responseDepartament = await departamentService.listDepartament({
        limit: 200,
        page: 1,
      });
      setListDepartament(responseDepartament.result);
      setLoading(false);
    };
    loadList();
  }, []);

  const handleInputDate = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputValueDate[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputValueDate(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputValueDate[inputName].required,
        error,
      },
    }));
  };

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputValue[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputValue(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputValue[inputName].required,
        error,
      },
    }));
  };

  const isFormValidDate = () => {
    const inputsWithError = validateForm(inputValueDate);
    let hasError = false;

    Object.entries(inputValueDate).forEach(allInput => {
      handleInputDate(allInput[1].value, allInput[0]);
    });

    Object.keys(inputValueDate).forEach(inputName => {
      if (inputValueDate[inputName].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputValue);
    let hasError = false;

    Object.entries(inputValue).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    Object.keys(inputValue).forEach(inputName => {
      if (inputValue[inputName].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const toSave = async () => {
    if (typePage) {
      if (isFormValid()) {
        try {
          const request = await eventsService.CreateEvent({
            name: inputValue.name.value,
            description: inputValue.description.value,
            churchId: inputValue.churchId.value,
            departamentId: inputValue.departamentId.value,
          });
          dates.forEach(async (element: any) => {
            await eventsService.createSchedules({
              eventId: request.id,
              dateEvent: element.date,
              startTime: element.startTime,
              endTime: element.endTime,
            });
          });
          setIsVisibleModalSucess(true);
        } catch {
          setIsVisibleModalErro(true);
        }
      } else {
        setIsVisibleModalErro(true);
      }
    } else if (isFormValid()) {
      try {
        await eventsService.editEventById({
          id: type,
          name: inputValue.name.value,
          description: inputValue.description.value,
          churchId: inputValue.churchId.value,
          departamentId: inputValue.departamentId.value,
        });
        setIsVisibleModalSucess(true);
      } catch {
        setIsVisibleModalErro(true);
      }
    } else {
      setIsVisibleModalErro(true);
    }
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Não foi possível salvar!
        </ModalDefault>
      )}
      {isVisibleModalSucess && (
        <ModalDefault success onClick={() => history.push('/eventos')}>
          Cadastro finalizado com sucesso!
        </ModalDefault>
      )}
      {isVisibleModalDate && (
        <Modal>
          <ContainerModal>
            <HeaderModal>
              <span>Cadastrar Data</span>
              <button
                type="button"
                onClick={() => setIsVisibleModalDate(false)}
              >
                <IconClose />
              </button>
            </HeaderModal>
            <Input
              label="Data"
              type="date"
              requiredLabel
              value={inputValueDate.date.value}
              onChange={e => handleInputDate(e.target.value, 'date')}
              error={inputValueDate.date.error}
            />
            <Input
              label="Horário de início"
              type="time"
              requiredLabel
              value={inputValueDate.startTime.value}
              onChange={e => handleInputDate(e.target.value, 'startTime')}
              error={inputValueDate.startTime.error}
            />
            <Input
              label=" horário de termino"
              type="time"
              requiredLabel
              value={inputValueDate.endTime.value}
              onChange={e => handleInputDate(e.target.value, 'endTime')}
              error={inputValueDate.endTime.error}
            />
            <Button
              onClick={() => {
                if (isFormValidDate()) {
                  setDates((currentValue: any) =>
                    currentValue.concat([
                      {
                        date: inputValueDate.date.value,
                        startTime: inputValueDate.startTime.value,
                        endTime: inputValueDate.endTime.value,
                      },
                    ]),
                  );
                  setInputValueDate(stateSchemaDate);
                  setIsVisibleModalDate(false);
                }
              }}
            >
              Salvar
            </Button>
          </ContainerModal>
        </Modal>
      )}
      <Page>
        <PageHeader
          title={typePage ? 'Criar Evento' : 'Editar Evento'}
          button="Salvar"
          onClick={() => toSave()}
        />
        <Container>
          <Content>
            <Input
              label="Nome"
              requiredLabel
              value={inputValue.name.value}
              onChange={e => handleInput(e.target.value, 'name')}
              error={inputValue.name.error}
            />
            <Input
              label="Descrição"
              requiredLabel
              value={inputValue.description.value}
              onChange={e => handleInput(e.target.value, 'description')}
              error={inputValue.description.error}
            />
            <InputSearch
              label="Departamento"
              lista={listDepartament}
              currentValue={currentDepartamentName}
              onChange={e => setInputDepartament(e.target.value)}
              onClickList={e => {
                setCurrentDepartamentName(e.name);
                setInputDepartament(e.name);
                handleInput(e.id, 'departamentId');
              }}
              valueInput={inputDepartament}
              value={inputDepartament}
              error={inputValue.departamentId.error}
            />
            <InputSearch
              label="Igreja(Local)"
              lista={listChurch}
              currentValue={currentChurchName}
              onChange={e => setInputChurch(e.target.value)}
              onClickList={e => {
                setCurrentChurchName(e.name);
                setInputChurch(e.name);
                handleInput(e.id, 'churchId');
              }}
              valueInput={inputChurch}
              value={inputChurch}
              error={inputValue.churchId.error}
            />
            {dates.length > 0 ? <span>Datas</span> : null}
            {dates.map((element: any) => (
              <DisplayDate>
                {format(new Date(element?.date), "dd 'de' MMMM", {
                  locale: pt,
                })}
                , das {element?.startTime} às {element?.endTime}
              </DisplayDate>
            ))}
            <span>
              <Button styles={1} onClick={() => setIsVisibleModalDate(true)}>
                + Adicionar datas
              </Button>
            </span>
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default EditEvents;
