/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IconEdit } from '~/assets/Icons';
import {
  Input,
  Loading,
  Button,
  ModalDefault,
  Page,
  PageHeader,
  Select,
} from '~/components';
import churchService from '~/services/churchService';
import sectorService from '~/services/sectorService';
import {
  generateDefaultInputValues,
  generateRequiredInputValues,
  isEmpty,
  validateForm,
} from '~/utils';
import estadosENUM from '~/utils/estados';
import isFormatCEP from '~/utils/isFormatCEP';
import ModalEditLeader from './modalLeader';
import {
  Container,
  ContainerBox,
  Content,
  ContentLeader,
  InputBox,
  Label,
  Title,
  // ViewMore,
} from './styles';
import ModalAddLeader from './modalAddLeader';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const EditChurch: React.FC = () => {
  const history = useHistory();
  const { type } = useParams<{ type: string }>();
  const [currentSectorName, setCurrentSectorName] = useState('');
  const [currentBiblicalSchoolDay, setCurrentBiblicalSchoolDay] = useState('');
  const [sectorName, setSectorName] = useState('');
  const [listSector, setListSector] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typePage, setTypePage] = useState(false);
  const [isVisibleModalLeader, setIsVisibleModalLeader] = useState(false);
  const [isVisibleModalAddLeader, setIsVisibleModalAddLeader] = useState(false);
  const [isVisibleModalLeaderSucess, setIsVisibleModalLeaderSucess] =
    useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalSucess, setIsVisibleModalSucess] = useState(false);
  const [currentLeader, setCurrentLeader] = useState<any>({});
  const [, setListLeader] = useState<any>([]);

  const stateSchema = {
    ...generateRequiredInputValues([
      'name',
      'sectorId',
      'street',
      'state',
      'city',
      'district',
      'zipCode',
      'members',
      'biblicalSchoolDay',
    ]),
    ...generateDefaultInputValues(['complement', 'number']),
  };

  const [inputValue, setInputValue] = useState<typeInput>(stateSchema);

  const loadChurch = async () => {
    setLoading(true);
    const responseChurch = await churchService.getchurchById(type);
    const responseAddress = await churchService.getAdrressById(
      responseChurch.address.id,
    );
    setCurrentLeader(responseChurch.actualChurchLeader);
    setListLeader(responseChurch.churchLeaders);
    setInputValue((prevState: any) => ({
      ...prevState,
      name: {
        ...prevState.name,
        value: responseChurch.name,
      },
      parson: {
        ...prevState.parson,
        value: responseChurch.pastor,
      },
      sectorId: {
        ...prevState.setorId,
        value: responseChurch.sector.id,
      },
      members: {
        ...prevState.members,
        value: responseChurch.members_number,
      },
      biblicalSchoolDay: {
        ...prevState.biblicalSchoolDay,
        value: responseChurch.biblical_school_day,
      },
      zipCode: {
        ...prevState.zipCode,
        value: responseAddress.zip_code,
      },
      street: {
        ...prevState.street,
        value: responseAddress.street,
      },
      number: {
        ...prevState.number,
        value: responseAddress.number,
      },
      complement: {
        ...prevState.complement,
        value: responseAddress.complement,
      },
      district: {
        ...prevState.district,
        value: responseAddress.district,
      },
      city: {
        ...prevState.city,
        value: responseAddress.city,
      },
      state: {
        ...prevState.state,
        value: responseAddress.state,
      },
    }));
    setSectorName(responseChurch.sector.name);
    setCurrentSectorName(responseChurch.sector.name);
    setCurrentBiblicalSchoolDay(responseChurch.biblical_school_day);
    setLoading(false);
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const response = await sectorService.listSector({ limit: 200, page: 1 });
      setListSector(response.result);

      if (type === 'create') {
        setTypePage(true);
        setLoading(false);
      } else {
        loadChurch();
      }
    };
    load();
  }, [type]);

  const updateCep = (data: any) => {
    setInputValue((prevState: any) => ({
      ...prevState,
      district: {
        value: data.district,
        isValid: true,
        required: inputValue.district.required,
        error: '',
      },
      street: {
        value: data.street,
        isValid: true,
        required: inputValue.street.required,
        error: '',
      },
      city: {
        value: data.city,
        isValid: true,
        required: inputValue.city.required,
        error: '',
      },
      state: {
        value: estadosENUM(data.state),
        isValid: true,
        required: inputValue.state.required,
        error: '',
      },
    }));
  };

  const requestCep = async (cep: string) => {
    try {
      const response = await churchService.getAddressByCep(cep);
      updateCep(response);
      return { isValid: true, error: '' };
    } catch (e) {
      return { isValid: false, error: 'Cep não encontrado' };
    }
  };

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputValue[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    if (inputName === 'zipCode' && value.length === 9) {
      const cep = value.replace(/[^\d]+/g, '');
      const response = await requestCep(cep);
      error = response.error;
      isValid = response.isValid;
    }

    setInputValue(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputValue[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputValue);
    let hasError = false;

    Object.entries(inputValue).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    if (sectorName === '') {
      hasError = true;
    }

    Object.keys(inputValue).forEach(inputName => {
      if (inputValue[inputName].error) {
        hasError = true;
      }
    });
    return isEmpty(inputsWithError) && !hasError;
  };

  const toSave = async () => {
    if (typePage) {
      if (isFormValid()) {
        try {
          const response = await churchService.createAddress({
            street: inputValue.street.value,
            number: inputValue.number.value,
            complement: inputValue.complement.value,
            district: inputValue.district.value,
            city: inputValue.city.value,
            state: inputValue.state.value,
            zipCode: inputValue.zipCode.value,
          });
          await churchService.createChurch({
            name: inputValue.name.value,
            sectorId: inputValue.sectorId.value,
            addressId: response.id,
            membersNumber: parseFloat(inputValue.members.value),
            biblicalSchoolDay: inputValue.biblicalSchoolDay.value,
          });
          setIsVisibleModalSucess(true);
        } catch {
          setIsVisibleModalErro(true);
        }
      } else {
        setIsVisibleModalErro(true);
      }
    } else if (isFormValid()) {
      try {
        await churchService.editChurchById({
          id: type,
          name: inputValue.name.value,
          sectorId: inputValue.sectorId.value,
          membersNumber: parseFloat(inputValue.members.value),
          biblicalSchoolDay: inputValue.biblicalSchoolDay.value,
        });
        setIsVisibleModalSucess(true);
      } catch {
        setIsVisibleModalErro(true);
      }
    } else {
      setIsVisibleModalErro(true);
    }
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }
  return (
    <>
      {isVisibleModalLeader && (
        <ModalEditLeader
          churchId={parseFloat(type)}
          data={currentLeader}
          onClickClose={() => setIsVisibleModalLeader(false)}
          onClickSave={() => {
            loadChurch();
            setIsVisibleModalLeaderSucess(true);
          }}
        />
      )}
      {isVisibleModalAddLeader && (
        <ModalAddLeader
          churchId={parseFloat(type)}
          onClickClose={() => setIsVisibleModalAddLeader(false)}
          onClickSave={() => loadChurch()}
        />
      )}
      {isVisibleModalLeaderSucess && (
        <ModalDefault
          success
          onClick={() => setIsVisibleModalLeaderSucess(false)}
        >
          Dirigente removido com sucesso!
        </ModalDefault>
      )}
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Não foi possível salvar!
        </ModalDefault>
      )}
      {isVisibleModalSucess && (
        <ModalDefault success onClick={() => history.push('/igrejas')}>
          Cadastro finalizado com sucesso!
        </ModalDefault>
      )}
      <Page>
        <PageHeader
          title={typePage ? 'Criar Igreja' : 'Editar Igreja'}
          button="Salvar"
          onClick={() => toSave()}
        />
        <Container>
          <Content>
            <Input
              label="Nome"
              requiredLabel
              value={inputValue.name.value}
              onChange={e => handleInput(e.target.value, 'name')}
              error={inputValue.name.error}
            />
            <div id="select">
              <Select
                label="Dia da EBD"
                lista={[
                  { id: 1, name: 'Domingo' },
                  { id: 2, name: 'Segunda-feira' },
                  { id: 3, name: 'Terça-feira' },
                  { id: 4, name: 'Quarta-feira' },
                  { id: 5, name: 'Quinta-feira' },
                  { id: 6, name: 'Sexta-feira' },
                  { id: 7, name: 'Sabado' },
                ]}
                erro={inputValue.biblicalSchoolDay.error}
                currentValue={currentBiblicalSchoolDay}
                onClickList={item => {
                  setCurrentBiblicalSchoolDay(item.name);
                  handleInput(item.name, 'biblicalSchoolDay');
                }}
              />
            </div>

            <div id="select">
              <Select
                label="Setor"
                lista={listSector}
                erro={inputValue.sectorId.error}
                currentValue={currentSectorName}
                onClickList={item => {
                  setSectorName(item.name);
                  setCurrentSectorName(item.name);
                  handleInput(item.id, 'sectorId');
                }}
              />
            </div>
            <Input
              label="Quantidade de pessoas"
              requiredLabel
              value={inputValue.members.value}
              onChange={e =>
                handleInput(e.target.value.replace(/\D/g, ''), 'members')
              }
              error={inputValue.members.error}
            />
            <Label>Dirigente:</Label>
            {currentLeader?.leader?.name ? (
              <ContentLeader>
                <span>
                  {currentLeader.leader.name}
                  <button
                    type="button"
                    onClick={() => setIsVisibleModalLeader(true)}
                  >
                    <IconEdit />
                  </button>
                </span>
                {/* {isVisibleModalViewMore && (
                  <table>
                    <thead>
                      <tr>
                        <th>Dirigente</th>
                        <th>Data inicial</th>
                        <th>Data final</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listLeader.shift().map((e: any) => (
                        <tr>
                          <td>{e?.leader?.name}</td>
                          <td>
                            {format(new Date(e?.begined_at), `dd/MM/yyyy`)}
                          </td>
                          <td>{e?.ended_at}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )} */}
              </ContentLeader>
            ) : (
              <Button onClick={() => setIsVisibleModalAddLeader(true)}>
                Adicionar dirigente
              </Button>
            )}
            {/* {listLeader.shift() !== undefined && (
              <ViewMore
                type="button"
                onClick={() =>
                  setisVisibleModalViewMore(!isVisibleModalViewMore)
                }
              >
                {!isVisibleModalViewMore ? 'Ver mais' : 'Ver menos'}
              </ViewMore>
            )} */}
            <Title>Endereço</Title>
            <ContainerBox>
              <InputBox>
                <Input
                  label="CEP"
                  requiredLabel
                  maxLenght={9}
                  value={isFormatCEP(inputValue.zipCode.value)}
                  error={inputValue.zipCode.error}
                  onChange={e => handleInput(e.target.value, 'zipCode')}
                  onBlur={async e => {
                    handleInput(e.target.value, 'zipCode');
                  }}
                />
                <Input
                  label="Número"
                  value={inputValue.number.value}
                  error={inputValue.number.error}
                  onChange={e =>
                    handleInput(e.target.value.replace(/[^\d]+/g, ''), 'number')
                  }
                  onBlur={e => handleInput(e.target.value, 'number')}
                />
              </InputBox>
              <InputBox>
                <Input
                  label="Logradouro"
                  requiredLabel
                  value={inputValue.street.value}
                  error={inputValue.street.error}
                  onChange={e => handleInput(e.target.value, 'street')}
                  onBlur={e => handleInput(e.target.value, 'street')}
                />
                <Input
                  label="Complemento"
                  value={inputValue.complement.value}
                  error={inputValue.complement.error}
                  onChange={e => handleInput(e.target.value, 'complement')}
                  onBlur={e => handleInput(e.target.value, 'complement')}
                />
              </InputBox>
            </ContainerBox>
            <ContainerBox>
              <InputBox>
                <Input
                  label="Estado"
                  requiredLabel
                  value={inputValue.state.value}
                  error={inputValue.state.error}
                  onChange={e => handleInput(e.target.value, 'state')}
                  onBlur={e => handleInput(e.target.value, 'state')}
                />
                <Input
                  label="Bairro"
                  requiredLabel
                  value={inputValue.district.value}
                  error={inputValue.district.error}
                  onChange={e => handleInput(e.target.value, 'district')}
                  onBlur={e => handleInput(e.target.value, 'district')}
                />
              </InputBox>
              <InputBox>
                <Input
                  label="Cidade"
                  requiredLabel
                  value={inputValue.city.value}
                  error={inputValue.city.error}
                  onChange={e => handleInput(e.target.value, 'city')}
                  onBlur={e => handleInput(e.target.value, 'city')}
                />
              </InputBox>
            </ContainerBox>
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default EditChurch;
