import styled from 'styled-components';

export const Child = styled.div`
  display: flex;
  width: 100%;
  height: auto;
`;

export const Container = styled.header`
  width: 100%;
  height: 10vh;
  padding: 1vh 2vh;
  background: ${({ theme }) => theme.Colors.Gray.Main};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
export const Logo = styled('img')<{ fix: boolean }>`
  width: auto;
  height: 5vh;

  @media (max-width: 350px) {
    width: 40vw;
    height: auto;
  }

  ${props => props.fix && 'margin-left: 5vw'}
`;

export const HeaderButton = styled('button')<{
  state: boolean;
  notification: boolean;
}>`
  display: flex;
  align-items: center;
  width: auto;
  height: 4vh;
  margin: 2vh;
  font-size: 4vh;
  color: ${({ theme }) => theme.Colors.White};

  ${props => props.state && 'opacity: 0'}
  ${props => props.notification && 'color: #30b4e5'}
`;

export const ButtonSair = styled.button`
  margin: 2vh;
  color: ${({ theme }) => theme.Colors.White};
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
    height: 20px;
    width: auto;
  }
`;

export const UserButton = styled.div`
  display: flex;
  align-items: center;
  width: 7vh;
  height: 7vh;
  margin: 2vh;
  font-size: 4vh;
  color: ${({ theme }) => theme.Colors.White};
  border: 2px solid;
  border-radius: 50%;
  @media (max-width: 480px) {
    height: 5vh;
    width: 5vh;
  }
`;

export const Mobile = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;
