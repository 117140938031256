import api from './api';

interface IListLeaders {
  limit?: number;
  page?: number;
  name?: string | undefined;
}
interface IEditLeaders {
  id: number | string;
  name: string;
}
interface ICreateLeader {
  name: string;
}
interface IHandleLeaderInChurch {
  leaderId: number;
  churchId: number;
  begined: string;
}

class LeadersService {
  async listLeaders({
    limit = 200,
    page = 1,
    name = undefined,
  }: IListLeaders): Promise<any> {
    const response = await api.get(`/leaders`, {
      params: {
        limit,
        page,
        name,
      },
    });

    return response.data;
  }

  async getLeaderById(id: number | string): Promise<any> {
    const response = await api.get(`/leaders/${id}`);

    return response.data;
  }

  async deleteLeaderById(id: number | string): Promise<any> {
    const response = await api.delete(`/leaders/${id}`);

    return response.data;
  }

  async editLeaderById({ id, name }: IEditLeaders): Promise<any> {
    const response = await api.put(`/leaders/${id}`, {
      name,
    });

    return response.data;
  }

  async createLeader({ name }: ICreateLeader): Promise<any> {
    const response = await api.post(`/leaders`, {
      name,
    });

    return response.data;
  }

  async addLeaderInChurch({
    leaderId,
    churchId,
    begined,
  }: IHandleLeaderInChurch): Promise<any> {
    const response = await api.patch(`/churchs/${churchId}/leader/add`, {
      leader_id: leaderId,
      begined_at: begined,
    });
    return response.data;
  }

  async removeLeaderInChurch({
    leaderId,
    churchId,
    begined,
  }: IHandleLeaderInChurch): Promise<any> {
    const response = await api.patch(`/churchs/${churchId}/leader/remove`, {
      leader_id: leaderId,
      ended_at: begined,
    });
    return response.data;
  }
}

export default new LeadersService();
