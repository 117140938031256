import api from './api';

class ReportsService {
  async exportExcel(): Promise<void> {
    const response: any = await api.get('/bs/frequencies/excel', {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Relatorio.xlsx');
    document.body.appendChild(link);
    link.click();
  }

  async exportExcelReportChurch({ year }: { year: string }): Promise<void> {
    const response: any = await api.get('/bs/frequencies/churchs/excel', {
      responseType: 'blob',
      params: {
        year,
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Relatorio.xlsx');
    document.body.appendChild(link);
    link.click();
  }

  async exportExcelReportLeader({ year }: { year: string }): Promise<void> {
    const response: any = await api.get('/bs/frequencies/leaders/excel', {
      responseType: 'blob',
      params: {
        year,
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Relatorio.xlsx');
    document.body.appendChild(link);
    link.click();
  }

  async reportsFrequencyLeadersForChurchs({
    church = undefined,
    sector,
    year,
    limit,
    page,
  }: {
    church: string | undefined;
    sector: string | undefined;
    year: string | undefined;
    limit: string;
    page: string;
  }): Promise<any> {
    const response = await api.get(`/bs/frequencies/churchs`, {
      params: {
        church,
        sector,
        year,
        limit,
        page,
      },
    });

    return response.data;
  }

  async reportsFrequencyLeader({
    name = undefined,
    sector,
    year,
    limit,
    page,
  }: {
    name: string | undefined;
    sector: string | undefined;
    year: string | undefined;
    limit: string;
    page: string;
  }): Promise<any> {
    const response = await api.get(`/bs/frequencies/leaders`, {
      params: {
        leader: name,
        sector,
        year,
        limit,
        page,
      },
    });

    return response.data;
  }

  async reportsFrequencyChurch({
    name = undefined,
  }: {
    name: string | undefined;
  }): Promise<any> {
    const response = await api.get(`/bs/frequencies/attendance`, {
      params: {
        church: name,
      },
    });

    return response.data;
  }
}

export default new ReportsService();
