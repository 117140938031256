import React from 'react';
import { IconClose } from '~/assets/Icons';
import { Modal, TablePage } from '~/components';
import { Color, Line } from '../../ManagersAssiduity/styles';
import { Content, TitleModal } from './styles';

interface IProps {
  data: any[];
  onClose: () => void;
}

const ModalDetails: React.FC<IProps> = ({ data, onClose }: IProps) => {
  const coluna = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      rowScope: 'row',
    },
    {
      title: 'Pessoas na igreja',
      dataIndex: 'member',
      key: 'member',
    },
    {
      title: 'Presentes',
      dataIndex: 'presente',
      key: 'presente',
    },
    {
      dataIndex: 'attendance_percentage',
      key: 'attendance_percentage',
      render: (e: number | null) => (
        <Line>
          {e ? `${e}%` : ''} <Color cor={e} />
        </Line>
      ),
    },
  ];

  return (
    <Modal width="1000px">
      <>
        <TitleModal>
          <button type="button" onClick={onClose}>
            <IconClose />
          </button>
        </TitleModal>
        <Content>
          <b>Frequência da congregação</b>
          <TablePage dataSource={data} columns={coluna} />
        </Content>
      </>
    </Modal>
  );
};

export default ModalDetails;
