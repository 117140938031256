/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IconFilter, IconSearch } from '~/assets/Icons';
import { Loading, TablePage, Input, Button } from '~/components';
import reportsService from '~/services/reportsService';
import { BtSeeMore } from '../CongregationFrequency/styles';
import ModalExport from '../ModalExport';
import ModalFilter from '../ModalFilter';
import ModalDetails from './ModalDetails';
import {
  Container,
  Content,
  HeaderTabs,
  Tabs,
  Title,
  Color,
  Line,
  ContainerSearch,
  ExportContainer,
} from './styles';

const ManagersAssiduity: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState('2023');
  const [time, setTime] = useState<any>(null);
  const [filterModal, setFilterModal] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [dataFilter, setDataFilter] = useState({ name: '', id: 0 });
  const [dataSource, setDataSource] = useState([]);
  const [exportModal, setExportModal] = useState(false);
  const [detailsReport, setDetailsReport] = useState(false);
  const [infoDetailsReport, setInfoDetailsReport] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [limit] = useState(10);
  const [tabs, setTabs] = useState({
    church: true,
    manager: false,
  });

  const typeCenter = 'center' as const;

  const columns = [
    {
      title: 'Igreja',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Faltas',
      dataIndex: 'isNotPresent',
      key: 'isNotPresent',
      align: typeCenter,
    },
    {
      title: 'Presenças',
      dataIndex: 'isPresent',
      key: 'isPresent',
      align: typeCenter,
    },
    {
      title: 'Total',
      key: 'total',
      align: typeCenter,
      render: (e: any) => `${e.total}/${e.lessonsTotal}`,
    },
    {
      dataIndex: 'attendancePercentage',
      key: 'attendancePercentage',
      width: '80px',
      render: (e: number | null) => (
        <Line>
          {e ? `${e}%` : '0%'} <Color cor={e} />
        </Line>
      ),
    },
  ];
  const columnsLeaders = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Faltas',
      dataIndex: 'isNotPresent',
      key: 'isNotPresent',
      align: typeCenter,
    },
    {
      title: 'Presenças',
      dataIndex: 'isPresent',
      key: 'isPresent',
      align: typeCenter,
    },
    {
      title: 'Total',
      key: 'total',
      align: typeCenter,
      render: (e: any) => `${e.total}/${e.lessonsTotal}`,
    },
    {
      dataIndex: 'attendancePercentage',
      key: 'attendancePercentage',
      width: '80px',
      render: (e: number | null) => (
        <Line>
          {e ? `${e}%` : '0%'} <Color cor={e} />
        </Line>
      ),
    },
    {
      key: 'seeMore',
      width: '150px',
      render: (e: any) => (
        <BtSeeMore
          onClick={() => {
            setDetailsReport(true);
            setInfoDetailsReport(e);
          }}
        >
          Ver detalhes
        </BtSeeMore>
      ),
    },
  ];

  const doSearch = async (val: string) => {
    setLoading(true);
    if (tabs.church) {
      console.log(page);
      const data = await reportsService.reportsFrequencyLeadersForChurchs({
        church: val === '' ? undefined : val,
        sector: dataFilter.id === 0 ? undefined : dataFilter.id.toString(),
        year,
        limit: limit.toString(),
        page: page.toString(),
      });
      console.log(page);
      setPage(data.info.page);
      setPages(data.info.pages);
      setDataSource(data.result);
    } else {
      const data = await reportsService.reportsFrequencyLeader({
        name: val === '' ? undefined : val,
        sector: dataFilter.id === 0 ? undefined : dataFilter.id.toString(),
        year,
        limit: limit.toString(),
        page: page.toString(),
      });
      setPage(data.info.page);
      setPages(data.info.pages);
      setDataSource(data.result);
    }
    setLoading(false);
  };

  useEffect(() => {
    doSearch(inputSearch);
  }, [tabs.church, year, page, dataFilter]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {exportModal && (
        <ModalExport tabs={tabs} onClose={() => setExportModal(false)} />
      )}
      {detailsReport && (
        <ModalDetails
          data={infoDetailsReport}
          onClose={() => setDetailsReport(false)}
        />
      )}
      {filterModal && (
        <ModalFilter
          data={dataFilter}
          year={year}
          setYear={e => setYear(e)}
          onClose={() => setFilterModal(false)}
          setData={e => {
            setPage(1);
            setInputSearch('');
            setDataFilter(e);
          }}
        />
      )}
      <Title>Assiduidade de dirigentes</Title>
      <Container>
        <HeaderTabs>
          <Tabs
            select={tabs.church}
            onClick={() => {
              setInputSearch('');
              setTabs({ manager: false, church: true });
            }}
          >
            Congregação
          </Tabs>
          <Tabs
            select={tabs.manager}
            onClick={() => {
              setInputSearch('');
              setTabs({ manager: true, church: false });
            }}
          >
            Dirigente
          </Tabs>
        </HeaderTabs>
        <Content>
          <ContainerSearch filter={dataFilter.id !== 0}>
            <Input
              placeholder={
                tabs.church ? 'Pesquisar igrejas' : 'Pesquisar dirigentes'
              }
              value={inputSearch}
              icon={<IconSearch />}
              onChange={e => {
                setInputSearch(e.target.value);
                if (time !== null) {
                  clearTimeout(time);
                }
                setTime(
                  setTimeout(() => {
                    doSearch(inputSearch);
                    setPage(1);
                  }, 1000),
                );
              }}
            />
            <button
              type="button"
              onClick={() => {
                setFilterModal(true);
              }}
            >
              <IconFilter />
            </button>
          </ContainerSearch>
          <ExportContainer>
            <Button onClick={() => setExportModal(true)}>Exportar excel</Button>
          </ExportContainer>
          <TablePage
            columns={tabs.church ? columns : columnsLeaders}
            dataSource={dataSource}
            rowKey="id"
            pagination={{
              showSizeChanger: false,
              current: page,
              pageSize: limit,
              total: pages * limit,
              onChange: e => {
                setPage(e);
              },
            }}
          />
        </Content>
      </Container>
    </>
  );
};

export default ManagersAssiduity;
