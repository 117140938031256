import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100%;
`;

export const Positive = styled.b`
  margin: 0 5px;
  color: #23ce6b;

  svg {
    margin: 0 5px;
  }
`;

export const Negative = styled.b`
  margin: 0 5px;
  color: ${({ theme }) => theme.Colors.Red};
  svg {
    margin: 0 5px;
  }
`;

export const Color = styled('div')<{ background: string }>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: ${props => props.background} !important;
`;

export const Header = styled.div`
  display: flex;
  justify-content: end;
  margin: 3%;
  div {
    width: 20%;
    margin-right: 15px;
    button,
    div {
      width: 100%;
    }
  }
  button {
    width: 30%;
  }
  @media (max-width: 768px) {
    justify-content: center;
    button {
      width: 95%;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
`;
