import { IconClose } from '~/assets/Icons';
import { Modal } from '~/components';
import { formatDecimal } from '~/utils/format/isFormatMoney';
import { Content, TitleModal, Line, Container, Title } from './styles';

interface IProps {
  list: any;
  onClick: () => void;
}

const ModalDetails: React.FC<IProps> = ({ onClick, list }: IProps) => {
  return (
    <Modal>
      <Container>
        <TitleModal>
          <button type="button" onClick={onClick}>
            <IconClose />
          </button>
        </TitleModal>
        <Content>
          <Title>Detalhes</Title>
          <Line>
            <b>Dirigente</b>
            <p>
              {list.pastor}({list.pastor_present ? 'Presente' : 'Ausente'})
            </p>
          </Line>
          <Line>
            <b>Oferta</b>
            <p>
              {'R$ '}
              {formatDecimal(list?.offers)}
            </p>
          </Line>
          <Line>
            <b>Dízimo</b>
            <p>
              {'R$ '}
              {formatDecimal(list?.tithes)}
            </p>
          </Line>
          {list.frequencyClasse.map((element: any) => {
            return (
              <>
                <Line>
                  <b>{element.classe.name}</b>{' '}
                  <p>{element.attendance} alunos</p>
                </Line>
                {element.justification && (
                  <Line>
                    <b>Justificativa</b> <p>{element.justification}</p>
                  </Line>
                )}
              </>
            );
          })}
          <Line>
            <b>Observação</b>
            <p>{list.comments}</p>
          </Line>
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalDetails;
