import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.Colors.Gray.Main};
`;

export const Screen = styled('div')<{ estado: boolean }>`
  @media (max-width: 480px) {
    width: 50vw;

    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(5px);

    position: fixed;
    top: 0;
    left: -100%;

    z-index: 999;

    ${props => props.estado && 'left: 0;'}
  }
`;

export const Content = styled('div')<{ estado: boolean; fix: boolean }>`
  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.Colors.White};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.28);
  border-radius: 0 10px 0 0;

  width: 20vw;
  min-width: 280px;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 1s;

  z-index: 999;

  .false {
    display: none;
  }

  ${props => props.estado && !props.fix && 'left: 0;'}
  ${props => props.fix && `left: 0; `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 10vh;
  padding: 0 2vh;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;

  max-height: 90vh;
  overflow: auto;
`;

export const HeaderButton = styled('button')<{ Pin: boolean | undefined }>`
  width: auto;
  height: 4vh;
  width: 4vh;
  margin: 1vh;
  display: flex;
  align-items: center;
  font-size: 4vh;
  color: ${({ theme }) => theme.Colors.Gray};

  ${props => props.Pin && `display: none `}
`;
