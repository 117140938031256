import styled from 'styled-components';

export const Container = styled.div`
  margin: 3%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: 15px 0px;
  width: 100%;
  padding: 15px;

  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;

  font-size: 1rem;

  button {
    margin-top: 15px;
  }
  span + span {
    margin-top: 0px;
  }
  span {
    margin: 20px 0;
    div {
      width: 100%;
    }
    button {
      margin-top: 0px;
    }
  }
`;

// export const Question = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;

//   margin: 20px 0;

//   button {
//     margin: 0px;
//   }
// `;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 20px;
  margin-bottom: 0px;
`;
export const SubTitle = styled.h6`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-bottom: 20px;
`;

export const Select = styled('select')<{ error: boolean }>`
  border: 1px solid ${({ theme }) => theme.Colors.Blue.Main};

  border-radius: 5px;
  height: 45px;
  padding: 0px 15px;

  background-color: #fff;
  color: rgba(0, 0, 0, 0.5);
  ${props => props.error && 'border: 1px solid #DF0A24;'}
`;

export const ErroAlert = styled.p`
  margin-top: 4px;
  color: #df0a24;
  font-size: 10px;
  min-height: 16px;
  margin-top: -20px;
`;

export const TextArea = styled.textarea`
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid ${({ theme }) => theme.Colors.Blue.Main};
`;
