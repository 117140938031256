import api from './api';

interface IListAreas {
  limit?: number;
  page?: number;
}
interface IEditAreas {
  id: number | string;
  name: string;
  color: string;
}
interface ICreateArea {
  name: string;
  color: string;
}

class AreasService {
  async listArea({ limit = 200, page = 1 }: IListAreas): Promise<any> {
    const response = await api.get(`/areas`, {
      params: {
        limit,
        page,
      },
    });

    return response.data;
  }

  async getAreaById(id: number | string): Promise<any> {
    const response = await api.get(`/areas/${id}`);

    return response.data;
  }

  async deleteAreaById(id: number | string): Promise<any> {
    const response = await api.delete(`/areas/${id}`);

    return response.data;
  }

  async editAreaById({ id, name, color }: IEditAreas): Promise<any> {
    const response = await api.put(`/areas/${id}`, {
      name,
      color,
    });

    return response.data;
  }

  async createArea({ name, color }: ICreateArea): Promise<any> {
    const response = await api.post(`/areas`, {
      name,
      color,
    });

    return response.data;
  }
}

export default new AreasService();
