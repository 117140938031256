import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100%;
`;

export const BtEdit = styled.a`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Blue.Main};
  }
`;

export const BtTrash = styled('a')<{ disabled?: boolean }>`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Red};
    ${props => props.disabled && 'color: #c4c4c4;'}
  }
`;

export const Picture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5);
`;

export const ContentModal = styled.div`
  margin-top: 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;

  svg {
    margin-bottom: 10px;
  }

  b {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
  }

  p {
    font-size: 14px;
    width: 100%;
    word-wrap: break-word;
    text-align: center;
  }
`;

export const TitleModal = styled.div`
  b {
    font-size: 18px;
    margin-top: 4px;
  }
  svg {
    color: #488cfa;
  }
  display: flex;
  justify-content: right;
  margin-bottom: -20px;
`;

export const DeletDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
  b {
    margin-bottom: 10px;
  }
  button {
    margin-top: 10px;
  }
`;

export const ClasseList = styled('div')<{ state: 1 | 2 | 3 }>`
  display: flex;
  color: #323232;
  margin: 5px 0px;
  width: 100%;
  border-radius: 5px;
  padding: 4px;
  font-weight: 500;
  justify-content: center;
  ${props => {
    switch (props.state) {
      case 1:
        return 'background: #A0F8B4;';
      case 2:
        return 'background: #E06967;';
      default:
        return 'background: transparent; margin: 0px; padding: 2px;';
    }
  }}
`;
