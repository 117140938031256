import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconDelete, IconEdit } from '~/assets/Icons';
import {
  Page,
  TablePage,
  PageHeader,
  ModalDelete,
  Loading,
} from '~/components';
import departamentService from '~/services/departamentService';
import { BtEdit, BtTrash, Content, Picture } from './styles';
import ImgDefault from '~/assets/Images/ImgDefault.png';

const Departament: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [limit] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [handleIdDelete, setHandleIdDelete] = useState(0);

  const history = useHistory();

  const loadDepartament = async ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    setLoading(true);
    const res = await departamentService.listDepartament({
      limit,
      page,
    });

    setDataSource(res.result);
    setPage(res.info.page);
    setPages(res.info.pages);
    setLoading(false);
  };

  useEffect(() => {
    loadDepartament({ limit, page });
  }, [limit, page]);

  const columns = [
    {
      key: 'picture',
      dataIndex: 'file',
      width: '40px',
      render: (e: any) => (
        <>
          <Picture src={e?.path || ImgDefault} />
        </>
      ),
    },
    {
      title: 'Sigla',
      dataIndex: 'initials',
      key: 'initials',
    },
    {
      title: 'Departamento',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ações',
      key: 'action',
      width: '100px',
      dataIndex: 'id',
      render: (e: number) => (
        <>
          <BtTrash
            onClick={() => {
              setHandleIdDelete(e);
              setIsVisibleModalDelete(true);
            }}
          >
            <IconDelete />
          </BtTrash>
          <BtEdit onClick={() => history.push(`/departamento/${e}`)}>
            <IconEdit />
          </BtEdit>
        </>
      ),
    },
  ];

  const deleteSector = async () => {
    setLoading(true);
    await departamentService.deleteDepartamentById(handleIdDelete);
    setIsVisibleModalDelete(false);
    loadDepartament({ limit: 10, page: 1 });
    setLoading(false);
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalDelete && (
        <ModalDelete
          text="setores"
          onClickCancel={() => {
            setIsVisibleModalDelete(false);
            setHandleIdDelete(0);
          }}
          onClickDelete={() => deleteSector()}
        />
      )}
      <Page>
        <PageHeader
          title="Departamentos"
          button="+ Adicionar novo departamento"
          onClick={() => history.push(`/departamento/create`)}
        />
        <Content>
          <TablePage
            columns={columns}
            dataSource={dataSource}
            pagination={{
              current: page,
              showSizeChanger: false,
              pageSize: limit,
              total: pages * limit,
              onChange: e => {
                setPage(e);
              },
            }}
            rowKey="id"
          />
        </Content>
      </Page>
    </>
  );
};

export default Departament;
