import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconDelete, IconEdit } from '~/assets/Icons';
import {
  Page,
  TablePage,
  PageHeader,
  Loading,
  ModalDelete,
  ModalDefault,
  Button,
} from '~/components';
import userService from '~/services/userService';
import { BtEdit, BtTrash, Content } from './styles';

const UsersEBD: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [, setPages] = useState(0);
  const [limit] = useState(10);
  const [dataSource, setDataSource] = useState<any>([]);
  const [input, setInput] = useState('');
  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [handleIdDelete, setHandleIdDelete] = useState(0);

  const history = useHistory();

  const loadUsers = async ({
    // limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    setLoading(true);

    if (page > 1) {
      const res = await userService.listUsersBs({ page });
      setDataSource((prevState: any) => [...prevState, ...res.result]);
      setPage((prevState: any) => {
        if (res.info.pages <= prevState) {
          return prevState;
        }
        return res.info.page + 1;
      });
    } else {
      const res = await userService.listUsersBs({ page });
      setDataSource(res.result);
      setPage(res.info.page + 1);
      setPages(res.info.pages);
    }

    setLoading(false);
  };
  useEffect(() => {
    loadUsers({ limit, page });
  }, [page, limit]);

  const restoreUser = async (id: string | number) => {
    setLoading(true);
    try {
      await userService.restoreUser(id);
    } catch {
      setIsVisibleModalErro(true);
    }
    await loadUsers({ limit, page: 1 });
    setLoading(false);
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Função',
      dataIndex: 'profile',
      key: 'profile',
      render: (e: { name: string }) => e?.name,
    },
    {
      title: 'Igreja',
      dataIndex: 'church',
      key: 'church',
      render: (e: { name: string }) => e?.name,
    },
    {
      title: 'Ações',
      key: 'action',
      width: '100px',
      render: (e: any) => {
        if (e.deleted_at) {
          return <Button onClick={() => restoreUser(e.id)}>Restaurar</Button>;
        }
        return (
          <>
            <BtTrash
              onClick={() => {
                setHandleIdDelete(e.id);
                setIsVisibleModalDelete(true);
              }}
            >
              <IconDelete />
            </BtTrash>
            <BtEdit onClick={() => history.push(`/usuario-ebd/${e.id}`)}>
              <IconEdit />
            </BtEdit>
          </>
        );
      },
    },
  ];

  const deleteUser = async () => {
    setLoading(true);
    try {
      await userService.deleteUser(handleIdDelete);
    } catch {
      setIsVisibleModalErro(true);
    }
    setIsVisibleModalDelete(false);
    await loadUsers({ limit, page: 1 });
    setLoading(false);
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalDelete && (
        <ModalDelete
          text="usuário"
          onClickCancel={() => {
            setIsVisibleModalDelete(false);
            setHandleIdDelete(0);
          }}
          onClickDelete={() => deleteUser()}
        />
      )}
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Ops algo deu errado
        </ModalDefault>
      )}

      <Page>
        <PageHeader
          title="Usuários EBD"
          button="+ Adicionar novo usuário EBD"
          onClick={() => history.push(`/usuario-ebd/create`)}
          onChange={e => setInput(e)}
        />
        <Content>
          <TablePage
            columns={columns}
            dataSource={dataSource.filter((element: any) =>
              element.name.toLowerCase().includes(input.toLowerCase()),
            )}
            // pagination={{
            //   current: page,
            //   showSizeChanger: false,
            //   pageSize: limit,
            //   total: pages * limit,
            //   onChange: e => {
            //     setPage(e);
            //   },
            // }}
            rowKey="id"
          />
        </Content>
      </Page>
    </>
  );
};

export default UsersEBD;
