const isFormatCPF = (cpf: string): string => {
  let v = cpf;
  v = v.replace(/\D/g, '');

  v = v.replace(/(\d{3})(\d)/, '$1.$2');
  v = v.replace(/(\d{3})(\d)/, '$1.$2');
  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

  return v;
};

export default isFormatCPF;
