import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconDelete, IconEdit } from '~/assets/Icons';
import {
  Page,
  TablePage,
  PageHeader,
  ModalDelete,
  Loading,
  ModalDefault,
} from '~/components';
import leadersService from '~/services/leadersService';
import { BtEdit, BtTrash, Content } from './styles';

const Leaders: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [, setPages] = useState(0);
  const [limit] = useState(200);
  const [dataSource, setDataSource] = useState([]);
  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [menssageModalErro, setMenssageModalErro] = useState('');
  const [handleIdDelete, setHandleIdDelete] = useState(0);
  const [input, setInput] = useState('');

  const history = useHistory();

  const loadLeaderss = async ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    setLoading(true);
    const res = await leadersService.listLeaders({
      limit,
      page,
    });

    setDataSource(res.result);
    setPage(res.info.page);
    setPages(res.info.pages);
    setLoading(false);
  };

  useEffect(() => {
    loadLeaderss({ limit, page });
  }, [limit, page]);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ações',
      key: 'action',
      width: '120px',
      dataIndex: 'id',
      render: (e: number) => (
        <>
          <BtTrash
            onClick={() => {
              setHandleIdDelete(e);
              setIsVisibleModalDelete(true);
            }}
          >
            <IconDelete />
          </BtTrash>
          <BtEdit onClick={() => history.push(`/dirigente/${e}`)}>
            <IconEdit />
          </BtEdit>
        </>
      ),
    },
  ];

  const deleteLeaders = async () => {
    setLoading(true);
    try {
      await leadersService.deleteLeaderById(handleIdDelete);
      setIsVisibleModalDelete(false);
      loadLeaderss({ limit: 10, page: 1 });
    } catch (e: any) {
      setIsVisibleModalDelete(false);
      setMenssageModalErro(JSON.parse(e.request.response).message);
      setIsVisibleModalErro(true);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          {menssageModalErro}
        </ModalDefault>
      )}
      {isVisibleModalDelete && (
        <ModalDelete
          text="dirigente"
          onClickCancel={() => {
            setIsVisibleModalDelete(false);
            setHandleIdDelete(0);
          }}
          onClickDelete={() => deleteLeaders()}
        />
      )}
      <Page>
        <PageHeader
          title="Dirigentes"
          button="+ Adicionar novo dirigente"
          onChange={e => setInput(e)}
          onClick={() => history.push(`/dirigente/create`)}
        />
        <Content>
          <TablePage
            columns={columns}
            dataSource={dataSource.filter((element: any) =>
              element.name.toLowerCase().includes(input.toLowerCase()),
            )}
            //  pagination={{
            //    showSizeChanger: false,
            //    current: page,
            //    pageSize: limit,
            //    total: pages * limit,
            //    onChange: e => {
            //      setPage(e);
            //    },
            //  }}
            rowKey="id"
          />
        </Content>
      </Page>
    </>
  );
};

export default Leaders;
