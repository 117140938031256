import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled('div')<{ fix: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 30px;

  ${props =>
    props.fix && 'position: absolute; top: 10vh; left: 20vw; width: 79vw;'}
`;

export const BackButton = styled.button`
  color: #323232;
  font-weight: 700;
  position: absolute;
  left: 30px;
  top: 15px;
  font-size: 16px;
`;
