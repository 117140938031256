import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Construction from '~/pages/Construction';
import Login from '~/pages/Login';
import Home from '~/pages/Home';
import Area from '~/pages/Area';
import EditArea from '~/pages/Area/Edit';
import Sector from '~/pages/Sectors';
import EditSector from '~/pages/Sectors/Edit';
import Departament from '~/pages/Departament';
import EditDepartament from '~/pages/Departament/Edit';
import Church from '~/pages/Church';
import EditChurch from '~/pages/Church/Edit';
import Events from '~/pages/Events';
import EditEvents from '~/pages/Events/Edit';
import Users from '~/pages/User';
import EditUser from '~/pages/User/Edit';
import Profiles from '~/pages/Profiles';
import EditProfile from '~/pages/Profiles/Edit';
import ReportsEBD from '~/pages/EBD/V2';
import EBD from '~/pages/EBD';
import UsersEBD from '~/pages/EBD/User';
import ChurchEBD from '~/pages/EBD/Church';
import EditChurchEBD from '~/pages/EBD/Church/Edit';
import EditUsersEBD from '~/pages/EBD/User/Edit';
import EditFrequencyEBD from '~/pages/EBD/FrequencyEdit';
import CreateFrequencyEBD from '~/pages/EBD/Frequency';
import RedefinePassword from '~/pages/RedefinePassword';
import Leaders from '~/pages/Leaders';
import EditLeaders from '~/pages/Leaders/Edit';
import Sent from '~/pages/EBD/UpdateRegistration/Sent';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/404" component={Construction} />
    <Route path="/home" exact component={Home} isPrivate />
    <Route path="/areas" exact component={Area} isPrivate />
    <Route path="/area/:type" exact component={EditArea} isPrivate />
    <Route path="/setores" exact component={Sector} isPrivate />
    <Route path="/setor/:type" exact component={EditSector} isPrivate />
    <Route path="/departamentos" exact component={Departament} isPrivate />
    <Route
      path="/departamento/:type"
      exact
      component={EditDepartament}
      isPrivate
    />
    <Route path="/igrejas" exact component={Church} isPrivate />
    <Route path="/igreja/:type" exact component={EditChurch} isPrivate />
    <Route path="/eventos" exact component={Events} isPrivate />
    <Route path="/evento/:type" exact component={EditEvents} isPrivate />
    <Route path="/usuarios" component={Users} isPrivate />
    <Route path="/usuario/:type" component={EditUser} isPrivate />
    <Route path="/perfis" component={Profiles} isPrivate />
    <Route path="/perfil/:type" component={EditProfile} isPrivate />
    <Route path="/usuarios-ebd" component={UsersEBD} isPrivate />
    <Route path="/usuario-ebd/:type" component={EditUsersEBD} isPrivate />
    <Route path="/relatorios-ebd" component={ReportsEBD} isPrivate />
    <Route path="/igrejas-ebd" component={ChurchEBD} isPrivate />
    <Route path="/igreja-ebd/:type" component={EditChurchEBD} isPrivate />
    <Route path="/dirigentes" component={Leaders} isPrivate />
    <Route path="/dirigente/:type" component={EditLeaders} isPrivate />
    <Route path="/senha" component={RedefinePassword} isPrivate />
    <Route path="/frequencias-ebd" component={EBD} isPrivate />
    <Route
      path="/frequencia-ebd/criar"
      component={CreateFrequencyEBD}
      isPrivate
    />
    <Route
      path="/frequencia-ebd/:type"
      component={EditFrequencyEBD}
      isPrivate
    />
    <Route
      path="/solicitacao-de-atualizacao-classes"
      component={Sent}
      isPrivate
    />
  </Switch>
);

export default Routes;
