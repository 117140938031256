import React from 'react';

import constructionSvg from '~/assets/construction.svg';
import { Container } from './styles';

const Construction: React.FC = () => {
  return (
    <Container>
      <div>
        <div
          style={{
            width: '100%',
            height: '20vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h2 style={{ fontSize: '30px', color: '#fff', textAlign: 'center' }}>
            Backoffice
          </h2>
          <h2 style={{ fontSize: '30px', color: '#fff', textAlign: 'center' }}>
            Em construção
          </h2>
        </div>
        <div
          style={{
            width: '100%',
            height: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={constructionSvg}
            alt="In progress"
            style={{ width: '95%', maxHeight: '100%', alignSelf: 'flex-end' }}
          />
        </div>
      </div>
    </Container>
  );
};

export default Construction;
