import React from 'react';
import { Button, Input } from '~/components';
import { ContainerSearch, Header, Title } from './styles';

interface IProps {
  button?: any;
  title: any;
  onClick?: any;
  disabled?: boolean;
  onChange?: (e: any) => void | null;
  value?: string | undefined;
}

const defaultProps = {
  disabled: false,
  onChange: undefined,
  value: undefined,
  button: null,
  onClick: null,
};

const PageHeader: React.FC<IProps> = ({
  button,
  title,
  onClick,
  disabled,
  onChange,
  value,
}: IProps) => {
  const insertInput = () => {
    if (onChange !== undefined) {
      if (value === undefined) {
        return (
          <ContainerSearch>
            <Input
              placeholder="Pesquisar"
              onChange={(e: any) => onChange(e.target.value)}
            />
          </ContainerSearch>
        );
      }
      return (
        <ContainerSearch>
          <Input
            placeholder="Pesquisar"
            value={value}
            onChange={(e: any) => onChange(e.target.value)}
          />
        </ContainerSearch>
      );
    }
    return null;
  };

  return (
    <Header>
      <Title>{title}</Title>
      {insertInput()}
      {button && (
        <Button responsiveness disabled={disabled} onClick={onClick}>
          {button}
        </Button>
      )}
    </Header>
  );
};

export default PageHeader;

PageHeader.defaultProps = defaultProps;
