import { IconClose } from '~/assets/Icons';
import { Modal, Button } from '~/components';
import { DeletDiv, ContentDelete, TitleModal } from './styles';

interface IProps {
  text: string;
  onClickCancel: () => void;
  onClickDelete: () => void;
}

const ModalDelete: React.FC<IProps> = ({
  text,
  onClickCancel,
  onClickDelete,
}: IProps) => {
  return (
    <Modal>
      <DeletDiv>
        <TitleModal>
          <button type="button" onClick={() => onClickCancel()}>
            <IconClose />
          </button>
        </TitleModal>
        <b>Tem certeza que deseja excluir {text}?</b>
        <ContentDelete>
          <Button styles={1} onClick={() => onClickCancel()}>
            Não
          </Button>
          <Button onClick={() => onClickDelete()}>Sim</Button>
        </ContentDelete>
      </DeletDiv>
    </Modal>
  );
};

export default ModalDelete;
