import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Input,
  Loading,
  ModalDefault,
  Page,
  PageHeader,
  Select,
} from '~/components';
import SectorService from '~/services/sectorService';
import userService from '~/services/userService';
import { generateRequiredInputValues, isEmpty, validateForm } from '~/utils';
import isFormatCPF from '~/utils/format/isFormatCPF';
import isFormatPhone from '~/utils/format/isFormatPhone';
import isValidCPF from '~/utils/validate/isValidCPF';
import isValidEmail from '~/utils/validate/isValidEmail';
import { Container, ContainerBox, Content } from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const EditUser: React.FC = () => {
  const history = useHistory();
  const { type } = useParams<{ type: string }>();
  const [loading, setLoading] = useState(false);
  const [menssageErro, setMenssageErro] = useState('');
  const [typePage, setTypePage] = useState(false);
  const [listProfile, setListProfile] = useState([]);
  const [profile, setProfile] = useState({ id: 0, name: '' });
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalSucess, setIsVisibleModalSucess] = useState(false);

  const stateSchema = {
    ...generateRequiredInputValues([
      'name',
      'cpf',
      'phone',
      'email',
      'password',
      'profileId',
    ]),
  };

  const [inputValue, setInputValue] = useState<typeInput>(stateSchema);

  useEffect(() => {
    if (type === 'create') {
      setTypePage(true);
    } else {
      const loadSector = async () => {
        setLoading(true);
        const response = await SectorService.getSectorById(type);
        setInputValue((prevState: any) => ({
          ...prevState,
          name: {
            ...prevState.name,
            value: response.name,
          },
          color: {
            ...prevState.name,
            value: response.color,
          },
        }));
        setLoading(false);
      };
      loadSector();
    }
  }, [type]);

  useEffect(() => {
    const loadProfile = async () => {
      const response = await userService.listProfile();
      setListProfile(response.result);
    };

    loadProfile();
  }, []);

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputName === 'email' && !isValidEmail(value)) {
      error = 'e-mail inválido';
      isValid = false;
    }

    if (inputName === 'cpf' && !isValidCPF(value)) {
      error = 'cpf inválido';
      isValid = false;
    }

    if (inputName === 'phone' && value.length < 14) {
      error = 'campo insuficiente';
      isValid = false;
    }

    if (inputName === 'password' && value.length < 6) {
      error = 'digite no mínimo 6 caracteres';
      isValid = false;
    }

    if (inputName === 'name' && value.length < 10) {
      error = 'digite no mínimo 10 caracteres';
      isValid = false;
    }

    setInputValue(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputValue[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputValue);
    let hasError = false;

    Object.entries(inputValue).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    Object.keys(inputValue).forEach(inputName => {
      if (inputValue[inputName].error) {
        hasError = true;
      }
    });
    return isEmpty(inputsWithError) && !hasError;
  };

  const toSave = async () => {
    if (typePage) {
      if (isFormValid()) {
        try {
          await userService.createUser({
            name: inputValue.name.value,
            cpf: inputValue.cpf.value.replace(/\D/g, ''),
            password: inputValue.password.value,
            phone: inputValue.phone.value.replace(/\D/g, ''),
            email: inputValue.email.value,
            profileId: inputValue.profileId.value,
          });
          setIsVisibleModalSucess(true);
        } catch (e: any) {
          console.log(e.request.response);
          setIsVisibleModalErro(true);
          const erro = JSON.parse(e.request.response);
          setMenssageErro(`(${erro.message})`);
        }
      } else {
        setIsVisibleModalErro(true);
      }
    }
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Não foi possível salvar! {menssageErro}
        </ModalDefault>
      )}
      {isVisibleModalSucess && (
        <ModalDefault success onClick={() => history.push('/usuarios')}>
          Cadastro finalizado com sucesso!
        </ModalDefault>
      )}
      <Page>
        <PageHeader
          title={typePage ? 'Criar Usuário' : 'Editar Usuário'}
          button="Salvar"
          onClick={() => toSave()}
        />
        <Container>
          <Content>
            <ContainerBox>
              <Input
                label="Nome"
                requiredLabel
                value={inputValue.name.value}
                onChange={e => handleInput(e.target.value, 'name')}
                error={inputValue.name.error}
              />

              <Input
                label="CPF"
                requiredLabel
                maxLenght={14}
                value={isFormatCPF(inputValue.cpf.value)}
                onChange={e => handleInput(e.target.value, 'cpf')}
                error={inputValue.cpf.error}
              />

              <Input
                label="E-mail"
                type="email"
                requiredLabel
                value={inputValue.email.value}
                onChange={e => handleInput(e.target.value, 'email')}
                error={inputValue.email.error}
              />

              <Input
                label="Senha"
                type="password"
                requiredLabel
                value={inputValue.password.value}
                onChange={e => handleInput(e.target.value, 'password')}
                error={inputValue.password.error}
              />

              <Input
                label="Telefone"
                requiredLabel
                maxLenght={15}
                value={isFormatPhone(inputValue.phone.value)}
                onChange={e => handleInput(e.target.value, 'phone')}
                error={inputValue.phone.error}
              />
              <div>
                <Select
                  label="Perfil"
                  onClickList={item => {
                    setProfile(item);
                    handleInput(item.id, 'profileId');
                  }}
                  currentValue={profile.name}
                  lista={listProfile}
                  erro={inputValue.profileId.error}
                />
              </div>
            </ContainerBox>
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default EditUser;
