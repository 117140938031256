/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Input, Loading, Page } from '~/components';
import {
  generateDefaultInputValues,
  generateRequiredInputValues,
  isEmpty,
  validateForm,
} from '~/utils';
import ModalDefalut from '~/components/ModalDefault';
import {
  Container,
  Content,
  ErroAlert,
  Select,
  TextArea,
  Title,
} from './styles';
import BiblicalSchool from '~/services/BiblicalSchool';
import frequencyService from '~/services/frequencyService';
import { formatDecimal } from '~/utils/format/isFormatMoney';

const FrequencyEdit: React.FC = () => {
  const state: string[] = [];
  const stateJustification: string[] = [];
  const { type } = useParams<{ type: string }>();
  const history = useHistory();

  const stateSchema: any = {
    ...generateRequiredInputValues(['date', 'offers', 'tithes']),
    ...generateDefaultInputValues(['comments']),
  };
  const [dataSource, setDataSource] = useState<any>({});
  const [inputState, setInputState] = useState<any>(stateSchema);
  const [listClasses] = useState<any>([]);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingInput, setLoadingInput] = useState(false);
  const [parson, setParson] = useState<'' | boolean>('');
  const [parsonValue, setParsonValue] = useState<'' | '0' | '1'>('');
  const [erroParson, setErroParson] = useState('');
  const [isVisibleModalErroRequest, setIsVisibleModalErroRequest] =
    useState(false);

  useEffect(() => {
    const loadClasses = async () => {
      try {
        setLoading(true);
        const response = await frequencyService.ShowFrequency(type);
        setDataSource(response);

        response.frequencyClasse.forEach((element: any) => {
          listClasses.push(element.classe);
        });

        response.frequencyClasse.forEach((item: any) => {
          state.push(item.classe.id);
          stateJustification.push(`justification${item.classe.id}`);
        });
        const stateSchema = {
          ...generateRequiredInputValues([
            ...state,
            'date',
            'offers',
            'tithes',
          ]),
          ...generateDefaultInputValues(['comments', ...stateJustification]),
        };
        setInputState(stateSchema);
        setLoadingInput(true);
      } catch {
        setIsVisibleModalErroRequest(true);
      }

      setLoading(false);
    };
    loadClasses();
  }, []);

  useEffect(() => {
    if (loadingInput) {
      Object.entries(inputState).forEach(allInput => {
        if (allInput[0].includes('justification')) {
          return setInputState((prevState: any) => ({
            ...prevState,
            [allInput[0]]: {
              ...prevState[allInput[0]],
              value:
                dataSource.frequencyClasse[
                  dataSource.frequencyClasse.findIndex(
                    (element: any) =>
                      element.classe.id.toString() ===
                      allInput[0].replace('justification', ''),
                  )
                ]?.justification,
            },
          }));
        }

        if (!Number.isNaN(parseFloat(allInput[0]))) {
          return setInputState((prevState: any) => ({
            ...prevState,
            [allInput[0]]: {
              ...prevState[allInput[0]],
              value:
                dataSource.frequencyClasse[
                  dataSource.frequencyClasse.findIndex(
                    (element: any) =>
                      element.classe.id.toString() === allInput[0],
                  )
                ].attendance,
            },
          }));
        }

        if (allInput[0] === 'date') {
          return setInputState((prevState: any) => ({
            ...prevState,
            [allInput[0]]: {
              ...prevState[allInput[0]],
              value: dataSource[allInput[0]]?.split('T')[0],
            },
          }));
        }
        setInputState((prevState: any) => ({
          ...prevState,
          [allInput[0]]: {
            ...prevState[allInput[0]],
            value: dataSource[allInput[0]],
          },
        }));

        return null;
      });
      if (dataSource.pastor_present === false) {
        setParsonValue('0');
      }
      if (dataSource.pastor_present === true) {
        setParsonValue('1');
      }
      setParson(dataSource.pastor_present);
    }
  }, [loadingInput]);

  const handleInput = (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState![inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    if (parson === '') {
      hasError = true;
    }

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const validAll = async () => {
    Object.entries(inputState).forEach((allInput: any) => {
      handleInput(allInput[1].value, allInput[0]);
    });
    const create = async () => {
      const state: any = [];

      Object.entries(inputState).forEach((allInput: any) => {
        if (allInput[0].includes('justification')) {
          return null;
        }
        if (
          allInput[0] !== 'comments' &&
          allInput[0] !== 'tithes' &&
          allInput[0] !== 'offers' &&
          allInput[0] !== 'date' &&
          allInput[0] !== 'churchId'
        ) {
          if (allInput[1].value === '0' || allInput[1].value === 0) {
            return state.push({
              id: allInput[0],
              attendance: allInput[1].value,
              justification: inputState[`justification${allInput[0]}`].value,
            });
          }
          state.push({ id: allInput[0], attendance: allInput[1].value });
        }
        return null;
      });
      if (isFormValid()) {
        if (parson !== '') {
          try {
            await BiblicalSchool.EditFrequency(
              {
                date: new Date(`${inputState.date.value}T03:00:00`),
                pastor_present: parson,
                tithes: inputState.tithes.value,
                offers: inputState.offers.value,
                comments: inputState.comments.value,
                classes: state,
              },
              type,
              dataSource.church_id,
            );
            history.push('/frequencias-ebd');
          } catch {
            setIsVisibleModalErro(true);
          }
        } else {
          setErroParson('campo obrigatorio');
        }
      } else {
        setIsVisibleModalErro(true);
      }
    };
    create();
  };

  if (loading) {
    return <Loading />;
  }

  if (isVisibleModalErroRequest) {
    return (
      <ModalDefalut
        success={false}
        onClick={() => history.push('/relatorios-ebd')}
      >
        <>
          Ops... algo deu errado!
          <br />
          Tente novamente mais tarde.
        </>
      </ModalDefalut>
    );
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefalut
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Ops... algo deu errado!
        </ModalDefalut>
      )}
      <Page>
        <Container>
          <Content>
            <Title>Cadastrar frequência</Title>
            {/* <SubTitle>Valentina 1</SubTitle> */}
            <span>
              <Input
                label="Data da EBD"
                value={inputState.date.value}
                error={inputState.date.error}
                onChange={e => handleInput(e.target.value, 'date')}
                type="date"
              />
            </span>
            <span>
              <b>Pastor(Dirigente) da Congregação Presente?</b>
            </span>
            <span />
            <Select
              error={!!erroParson}
              onChange={e => {
                if (e.target.value === '0') {
                  setParson(false);
                  setParsonValue('0');
                }
                if (e.target.value === '1') {
                  setParson(true);
                  setParsonValue('1');
                }
                if (e.target.value === '') {
                  setParson('');
                  setParsonValue('');
                }
              }}
              value={parsonValue}
            >
              <option value=""> </option>
              <option value="1">Sim</option>
              <option value="0">Não</option>
            </Select>
            {erroParson && <ErroAlert>{erroParson}</ErroAlert>}
            {listClasses.map((item: any) => {
              return (
                <span key={item.id}>
                  <Input
                    type="number"
                    label={`Classe de ${item.name}:`}
                    placeholder="Quantidade de alunos"
                    error={inputState[item.id].error}
                    value={inputState[item.id].value}
                    onChange={e =>
                      handleInput(
                        e.target.value.replace(/[^\d]+/g, ''),
                        item.id,
                      )
                    }
                  />
                  {(inputState[item.id].value === '0' ||
                    inputState[item.id].value === 0) && (
                    <Input
                      label={`Justificativa da ausência(${item.name}):`}
                      placeholder="Justifique a ausência da turma"
                      error={inputState[`justification${item.id}`].error}
                      value={inputState[`justification${item.id}`].value}
                      onChange={e =>
                        handleInput(e.target.value, `justification${item.id}`)
                      }
                    />
                  )}
                </span>
              );
            })}
            <Input
              label="Valor Total de Oferta"
              value={formatDecimal(inputState.offers.value)}
              error={inputState.offers.error}
              onChange={e => handleInput(e.target.value, 'offers')}
            />
            <Input
              label="Valor Total de Dízimo"
              value={formatDecimal(inputState.tithes.value)}
              error={inputState.tithes.error}
              onChange={e => handleInput(e.target.value, 'tithes')}
            />
            <span>
              <b>Observação:</b>
              <TextArea
                value={inputState.comments.value}
                onChange={e => handleInput(e.target.value, 'comments')}
              />
            </span>
            <Button onClick={() => validAll()} disabled={!isFormValid()}>
              Salvar
            </Button>
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default FrequencyEdit;
