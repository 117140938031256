/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { BsEyeSlashFill } from 'react-icons/bs';
import { IoEyeSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Content, Img, PageLogin, Title } from './styles';
import { SVGLogin } from '~/assets/Images';
import logoAd from '~/assets/Images/logoAdAzul.png';
import { Button, Input, ModalDefault } from '~/components';
import { authRequest } from '~/store/modules/auth/actions';

const Login: React.FC = () => {
  const erro = useSelector((state: any) => state.auth.erro);
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const dispatch = useDispatch();
  const history = useHistory();
  const [fail, setFail] = useState(erro);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);

  useEffect(() => {
    setFail(erro);
  }, [erro]);

  useEffect(() => {
    if (isLogged) {
      history.push('/home');
    }
  }, [isLogged]);
  return (
    <>
      {fail && (
        <ModalDefault success={false} onClick={() => setFail(false)}>
          Por favor, verifique seu e-mail e sua senha e tente novamente!
        </ModalDefault>
      )}
      <Container
        onKeyUp={(e: any) => {
          if (e.key === 'Enter')
            dispatch(
              authRequest({
                email,
                password,
              }),
            );
        }}
      >
        <PageLogin>
          <Img>
            <SVGLogin />
            <Title>Sistema de Gestão - ADPB</Title>
          </Img>
        </PageLogin>
        <Content>
          <img src={logoAd} alt="Logo Assembleia de Deus" />
          <Input
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Input
            placeholder="Senha"
            type={!visiblePassword ? 'password' : 'text'}
            value={password}
            icon={
              !visiblePassword ? (
                <BsEyeSlashFill color="#619CFA" />
              ) : (
                <IoEyeSharp color="#619CFA" />
              )
            }
            onClick={() => setVisiblePassword(!visiblePassword)}
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            onClick={async () => {
              dispatch(
                authRequest({
                  email,
                  password,
                }),
              );
            }}
          >
            Entrar
          </Button>
        </Content>
      </Container>
    </>
  );
};

export default Login;
