import React, { useEffect, useState } from 'react';
import { IconClose } from '~/assets/Icons';
import {
  Page,
  TablePage,
  PageHeader,
  Loading,
  ModalDefault,
  Button,
  Modal,
} from '~/components';
import BiblicalSchool from '~/services/BiblicalSchool';
import frequencyService from '~/services/frequencyService';
import {
  ClasseList,
  Content,
  ContentModal,
  DeletDiv,
  TitleModal,
} from './styles';

const Sent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<any>([]);
  const [dataSourceDetails, setDataSourceDetails] = useState<any>({});
  const [dataSourceClasse, setDataSourceClasse] = useState<any>([]);
  const [input, setInput] = useState('');
  const [isVisibleModalDetails, setIsVisibleModalDetails] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [keptArr, setKeptArr] = useState([]);

  const loadReports = async () => {
    setLoading(true);
    const res = await BiblicalSchool.ListUpdateClasseRequest();
    setDataSource(res);
    setLoading(false);
  };
  useEffect(() => {
    const loadClasses = async () => {
      const res = await frequencyService.listAllClasses();
      setDataSourceClasse(res.result);
    };
    loadClasses();
    loadReports();
  }, []);

  const acceptRequest = async () => {
    setLoading(true);
    try {
      await frequencyService.ApproveRequestUpdate(dataSourceDetails.id);
      setIsVisibleModalDetails(false);
    } catch (e) {
      console.log(e);
      setIsVisibleModalErro(true);
    }
    await loadReports();
    setLoading(false);
  };

  const columns = [
    {
      title: 'Igreja',
      dataIndex: 'church',
      key: 'church',
      render: (e: { name: string }) => e?.name,
    },
    {
      key: 'action',
      width: '150px',
      render: (e: any) => (
        <Button
          onClick={() => {
            setDataSourceDetails(e);
            setKeptArr(
              e.classesId.filter(
                (classes: any) => !e.addedClassesId.includes(classes),
              ),
            );
            setIsVisibleModalDetails(true);
          }}
        >
          Ver Mais
        </Button>
      ),
    },
  ];

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Ops algo deu errado
        </ModalDefault>
      )}
      {isVisibleModalDetails && (
        <Modal>
          <DeletDiv>
            <TitleModal>
              <button
                type="button"
                onClick={() => setIsVisibleModalDetails(false)}
              >
                <IconClose />
              </button>
            </TitleModal>
            <ContentModal>
              {keptArr.length > 0 && (
                <>
                  <b>Classes Mantidas</b>
                  {keptArr.map((e: any) => {
                    const classe = dataSourceClasse.find(
                      (classe: any) => classe.id === e,
                    );
                    return <ClasseList state={3}>{classe.name}</ClasseList>;
                  })}
                </>
              )}
              {dataSourceDetails.addedClassesId.length > 0 && (
                <>
                  <b>Classes Adicionadas</b>
                  {dataSourceDetails.addedClassesId.map((e: any) => {
                    const classe = dataSourceClasse.find(
                      (classe: any) => classe.id === e,
                    );
                    return <ClasseList state={1}>{classe.name}</ClasseList>;
                  })}
                </>
              )}
              {dataSourceDetails.removedClassesId.length > 0 && (
                <>
                  <b>Classes Removidas</b>
                  {dataSourceDetails.removedClassesId.map((e: any) => {
                    const classe = dataSourceClasse.find(
                      (classe: any) => classe.id === e,
                    );
                    return <ClasseList state={2}>{classe.name}</ClasseList>;
                  })}
                </>
              )}
              <Button onClick={() => acceptRequest()}>Alterar</Button>
            </ContentModal>
          </DeletDiv>
        </Modal>
      )}

      <Page>
        <PageHeader
          title="Solicitações de Alteração"
          onChange={e => setInput(e)}
        />
        <Content>
          <TablePage
            columns={columns}
            dataSource={dataSource.filter((element: any) =>
              element?.church?.name.toLowerCase().includes(input.toLowerCase()),
            )}
            rowKey="id"
          />
        </Content>
      </Page>
    </>
  );
};

export default Sent;
