import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;

  background: #0ca1d0;
`;

export const PageLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: none;
  } ;
`;

export const Img = styled.div`
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  svg {
    width: 50%;
    height: fit-content;
  }
`;

export const Content = styled.div`
  border-radius: 30px 0 0 30px;
  box-shadow: -5px 2px 8px rgba(0, 0, 0, 0.25);
  height: 100vh;
  width: 40vw;
  padding: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;

  img {
    height: clamp(100px, 1vw + 0.1rem, 250px);
    width: auto;
    padding: 20px 0;
  }

  input {
    margin: 10px 0;
  }
  button {
    margin: 10px 0;
  }

  @media (max-width: 600px) {
    width: 100vw;
    border-radius: 0;
  } ;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 32px;
  padding: 20px 0;
  color: #ffffff;
`;

export const A = styled.a`
  margin-top: 10px;
  cursor: pointer;
`;
