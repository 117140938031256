import styled from 'styled-components';

export const Title = styled.h2`
  margin: 3% 3% 1% 3%;
  color: #1e3b69;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 0% 3% 3% 3%;
  border-radius: 3px;
  background-color: white;
  div {
    padding: 10px;
    div {
      padding: 0px;
    }
  }
`;

export const HeaderTabs = styled.div`
  margin: 0% 3% 0% 3%;
  display: flex;
`;

export const Tabs = styled('button')<{ select?: boolean }>`
  display: flex;
  padding: 5px 10px;
  margin: 0px 10px;
  border-radius: 5px 5px 0px 0px;
  background-color: white;

  ${props => !props.select && `background: #1e3b69; color: #ffffff;`}
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 2px 9px rgba(0, 0, 0, 0.2));
`;

export const ContainerSearch = styled.div<{ filter: boolean }>`
  display: flex;
  align-items: center;
  button {
    margin-left: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      path {
        ${({ filter }: { filter: boolean }) => filter && `fill: #488cfa`}
      }
    }
  }
  div {
    button {
      margin-left: initial;
      cursor: auto;
      svg {
        path {
          fill: initial;
        }
      }
    }
  }
`;

export const ExportContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  button {
    width: 30%;
    cursor: pointer;
  }
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Color = styled('div')<{ cor: number | null }>`
  border-radius: 50%;
  margin-left: 10px;
  content: ' ';
  height: 10px;
  width: 10px;
  background-color: ${props => {
    if (props.cor === null) {
      return '#df0a24 !important';
    }
    if (props.cor < 50) {
      return '#df0a24 !important';
    }
    if (props.cor < 75) {
      return '#FFCA3A !important';
    }
    return '#8AC926 !important';
  }};
`;
