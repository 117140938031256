import api from './api';

interface IListDepartaments {
  limit?: number;
  page?: number;
}
interface IEditDepartament {
  id: number | string;
  name: string;
  initials: string;
  color: string;
}
interface ICreateDepartament {
  name: string;
  initials: string;
  color: string;
}

class DepartamentService {
  async listDepartament({
    limit = 200,
    page = 1,
  }: IListDepartaments): Promise<any> {
    const response = await api.get(`/departaments`, {
      params: {
        limit,
        page,
      },
    });

    return response.data;
  }

  async listAllDepartament({
    limit = 200,
    page = 1,
  }: IListDepartaments): Promise<any> {
    const response = await api.get(`/departaments/all`, {
      params: {
        limit,
        page,
      },
    });

    return response.data;
  }

  async getDepartamentById(id: number | string): Promise<any> {
    const response = await api.get(`/departaments/${id}`);

    return response.data;
  }

  async deleteDepartamentById(id: number | string): Promise<any> {
    const response = await api.delete(`/departaments/${id}`);

    return response.data;
  }

  async editDepartamentById({
    id,
    name,
    color,
    initials,
  }: IEditDepartament): Promise<any> {
    const response = await api.put(`/departaments/${id}`, {
      name,
      color,
      initials,
    });

    return response.data;
  }

  async CreateDepartament({
    name,
    color,
    initials,
  }: ICreateDepartament): Promise<any> {
    const response = await api.post(`/departaments`, {
      name,
      color,
      initials,
    });

    return response.data;
  }
}

export default new DepartamentService();
