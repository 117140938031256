import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconDelete, IconEdit } from '~/assets/Icons';
import {
  Page,
  TablePage,
  PageHeader,
  ModalDelete,
  Loading,
} from '~/components';
import sectorService from '~/services/sectorService';
import { BtEdit, BtTrash, Content } from './styles';

const Sectors: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [limit] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [handleIdDelete, setHandleIdDelete] = useState(0);

  const history = useHistory();

  const loadSectors = async ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    setLoading(true);
    const res = await sectorService.listSector({
      limit,
      page,
    });

    setDataSource(res.result);
    setPage(res.info.page);
    setPages(res.info.pages);
    setLoading(false);
  };

  useEffect(() => {
    loadSectors({ limit, page });
  }, [limit, page]);

  const columns = [
    {
      title: 'Setor',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ações',
      key: 'action',
      width: '100px',
      dataIndex: 'id',
      render: (e: number) => (
        <>
          <BtTrash
            onClick={() => {
              setHandleIdDelete(e);
              setIsVisibleModalDelete(true);
            }}
          >
            <IconDelete />
          </BtTrash>
          <BtEdit onClick={() => history.push(`/setor/${e}`)}>
            <IconEdit />
          </BtEdit>
        </>
      ),
    },
  ];

  const deleteSector = async () => {
    setLoading(true);
    await sectorService.deleteSectorById(handleIdDelete);
    setIsVisibleModalDelete(false);
    loadSectors({ limit, page });
    setLoading(false);
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalDelete && (
        <ModalDelete
          text="setor"
          onClickCancel={() => {
            setIsVisibleModalDelete(false);
            setHandleIdDelete(0);
          }}
          onClickDelete={() => deleteSector()}
        />
      )}
      <Page>
        <PageHeader
          title="Setores"
          button="+ Adicionar novo setor"
          onClick={() => history.push(`/setor/create`)}
        />
        <Content>
          <TablePage
            columns={columns}
            dataSource={dataSource}
            pagination={{
              current: page,
              showSizeChanger: false,
              pageSize: limit,
              total: pages * limit,
              onChange: e => {
                setPage(e);
              },
            }}
            rowKey="id"
          />
        </Content>
      </Page>
    </>
  );
};

export default Sectors;
