import api from './api';

interface IListChurchs {
  // limit?: number;
  page?: number;
}
interface IEditChurch {
  id: number | string;
  name: string;
  sectorId: number | string;
  membersNumber: number;
  biblicalSchoolDay: string;
}
interface ICreateChurch {
  name: string;
  sectorId: number | string;
  addressId: number | string;
  membersNumber: number;
  biblicalSchoolDay: string;
}

interface ICreateAddress {
  street: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  zipCode: string;
}

class ChurchService {
  async listChurch({ page = 1 }: IListChurchs): Promise<any> {
    const response = await api.get(`/churchs`, {
      params: {
        page,
      },
    });

    return response.data;
  }

  async getchurchById(id: number | string): Promise<any> {
    const response = await api.get(`/churchs/${id}`);

    return response.data;
  }

  async deletechurchById(id: number | string): Promise<any> {
    const response = await api.delete(`/churchs/${id}`);

    return response.data;
  }

  async editChurchById({
    id,
    name,
    sectorId,
    membersNumber,
    biblicalSchoolDay,
  }: IEditChurch): Promise<any> {
    const response = await api.put(`/churchs/${id}`, {
      name,
      sector_id: sectorId,
      members_number: membersNumber,
      biblical_school_day: biblicalSchoolDay,
    });

    return response.data;
  }

  async createChurch({
    name,
    sectorId,
    addressId,
    membersNumber,
    biblicalSchoolDay,
  }: ICreateChurch): Promise<any> {
    const response = await api.post(`/churchs`, {
      name,
      sector_id: sectorId,
      address_id: addressId,
      members_number: membersNumber,
      biblical_school_day: biblicalSchoolDay,
    });

    return response.data;
  }

  async getAdrressById(id: number | string): Promise<any> {
    const response = await api.get(`/addresses/${id}`);

    return response.data;
  }

  async createAddress({
    street,
    number,
    complement,
    district,
    city,
    state,
    zipCode,
  }: ICreateAddress): Promise<any> {
    const response = await api.post(`/addresses`, {
      street,
      number,
      complement,
      district,
      city,
      state,
      zip_code: zipCode,
    });

    return response.data;
  }

  async getAddressByCep(cep: number | string): Promise<any> {
    const response = await api.get(`/addresses/cep/${cep}`);

    return response.data;
  }
}

export default new ChurchService();
