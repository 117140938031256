import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconDelete, IconEdit } from '~/assets/Icons';
import {
  Page,
  TablePage,
  PageHeader,
  ModalDelete,
  Loading,
  ModalDefault,
} from '~/components';
import areasService from '~/services/areasService';
import { BtEdit, BtTrash, Color, Content } from './styles';

const Area: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [limit] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [menssageModalErro, setMenssageModalErro] = useState('');
  const [handleIdDelete, setHandleIdDelete] = useState(0);

  const history = useHistory();

  const loadAreas = async ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    setLoading(true);
    const res = await areasService.listArea({
      limit,
      page,
    });

    setDataSource(res.result);
    setPage(res.info.page);
    setPages(res.info.pages);
    setLoading(false);
  };

  useEffect(() => {
    loadAreas({ limit, page });
  }, [limit, page]);

  const columns = [
    {
      title: 'Cor',
      dataIndex: 'color',
      key: 'color',
      width: '40px',
      render: (e: string) => <Color background={e} />,
    },
    {
      title: 'Área',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ações',
      key: 'action',
      width: '120px',
      dataIndex: 'id',
      render: (e: number) => (
        <>
          <BtTrash
            onClick={() => {
              setHandleIdDelete(e);
              setIsVisibleModalDelete(true);
            }}
          >
            <IconDelete />
          </BtTrash>
          <BtEdit onClick={() => history.push(`/area/${e}`)}>
            <IconEdit />
          </BtEdit>
        </>
      ),
    },
  ];

  const deleteArea = async () => {
    setLoading(true);
    try {
      await areasService.deleteAreaById(handleIdDelete);
      setIsVisibleModalDelete(false);
      loadAreas({ limit: 10, page: 1 });
    } catch (e: any) {
      setIsVisibleModalDelete(false);
      setMenssageModalErro(JSON.parse(e.request.response).message);
      setIsVisibleModalErro(true);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          {menssageModalErro}
        </ModalDefault>
      )}
      {isVisibleModalDelete && (
        <ModalDelete
          text="áreas"
          onClickCancel={() => {
            setIsVisibleModalDelete(false);
            setHandleIdDelete(0);
          }}
          onClickDelete={() => deleteArea()}
        />
      )}
      <Page>
        <PageHeader
          title="Áreas"
          button="+ Adicionar nova área"
          onClick={() => history.push(`/area/create`)}
        />
        <Content>
          <TablePage
            columns={columns}
            dataSource={dataSource}
            pagination={{
              showSizeChanger: false,
              current: page,
              pageSize: limit,
              total: pages * limit,
              onChange: e => {
                setPage(e);
              },
            }}
            rowKey="id"
          />
        </Content>
      </Page>
    </>
  );
};

export default Area;
