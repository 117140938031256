import React from 'react';
import ButtonDashboard from '~/components/ButtonDashboard';

import Page from '~/components/Page';
import { Container } from './styles';

const Home: React.FC = () => {
  return (
    <Page disabledBack>
      <Container>
        <h1>Home</h1>
        <ButtonDashboard />
      </Container>
    </Page>
  );
};

export default Home;
