import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Button, Input, ModalDefault, Page } from '~/components';
import { generateRequiredInputValues, validateForm } from '~/utils';
import { isEmpty } from '~/utils/validate';
import UserService from '~/services/userService';
import { Container, Content, Title } from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const RedefinePassword: React.FC = () => {
  const stateSchema = {
    ...generateRequiredInputValues([
      'lastPassword',
      'password',
      'confirmPassword',
    ]),
  };
  const [inputState, setInputState] = useState<typeInput>(stateSchema);
  const [isVisibleModalSuccess, setIsVisibleModalSuccess] = useState(false);
  const [isVisibleModalError, setIsVisibleModalError] = useState(false);
  const history = useHistory();

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputName === 'password' && value.length < 6) {
      error = 'Digite no mínimo 6 caracteres';
      isValid = false;
    }

    if (
      inputName === 'confirmPassword' &&
      inputState.password.value !== value
    ) {
      error = 'Senhas diferentes';
      isValid = false;
    }

    if (inputState[inputName].required && value.length === 0) {
      error = 'Campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const updatePassword = async () => {
    try {
      await UserService.updatePassword({
        actualPassword: inputState.lastPassword.value,
        password: inputState.password.value,
      });
      setIsVisibleModalSuccess(true);
    } catch {
      setIsVisibleModalError(true);
    }
  };

  return (
    <>
      {isVisibleModalSuccess && (
        <ModalDefault success onClick={() => history.push('/home')}>
          Senha trocada com sucesso!
        </ModalDefault>
      )}
      {isVisibleModalError && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalError(false)}
        >
          Não foi possível trocar a senha
        </ModalDefault>
      )}
      <Page>
        <Container>
          <Content>
            <Title>Redefinir senha</Title>
            <Input
              name="senhaAntiga"
              label="Senha antiga"
              id="senhaAntiga"
              type="password"
              onChange={e => handleInput(e.target.value, 'lastPassword')}
              error={inputState.lastPassword.error}
            />
            <Input
              name="password"
              label="Senha"
              id="inputPassword"
              type="password"
              onChange={e => handleInput(e.target.value, 'password')}
              error={inputState.password.error}
            />
            <Input
              name="ConfirmPassword"
              label="Confirmação de Senha"
              id="inputConfirmPassword"
              type="password"
              onChange={e => handleInput(e.target.value, 'confirmPassword')}
              error={inputState.confirmPassword.error}
            />
            <Button disabled={!isFormValid()} onClick={() => updatePassword()}>
              Salvar
            </Button>
            <Button styles={1} onClick={() => history.push('/home')}>
              Cancelar
            </Button>
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default RedefinePassword;
