import api from './api';

interface IListEvents {
  limit?: number;
  page?: number;
}
interface IEditEvent {
  id: number | string;
  name: string;
  description: string;
  churchId: number | string;
  departamentId: number | string;
}
interface ICreateEvent {
  name: string;
  description: string;
  churchId: number | string;
  departamentId: number | string;
}

interface ICreateSchedules {
  eventId: number;
  dateEvent: string;
  startTime: string;
  endTime: string;
}

class EventService {
  async listEvent({ limit = 200, page = 1 }: IListEvents): Promise<any> {
    const response = await api.get(`/events`, {
      params: {
        limit,
        page,
      },
    });

    return response.data;
  }

  async getEventById(id: number | string): Promise<any> {
    const response = await api.get(`/events/${id}`);

    return response.data;
  }

  async deleteEventById(id: number | string): Promise<any> {
    const response = await api.delete(`/events/${id}`);

    return response.data;
  }

  async editEventById({
    id,
    name,
    description,
    churchId,
    departamentId,
  }: IEditEvent): Promise<any> {
    const response = await api.put(`/events/${id}`, {
      name,
      description,
      church_id: churchId,
      departament_id: departamentId,
    });

    return response.data;
  }

  async CreateEvent({
    name,
    description,
    churchId,
    departamentId,
  }: ICreateEvent): Promise<any> {
    const response = await api.post(`/events`, {
      name,
      description,
      church_id: churchId,
      departament_id: departamentId,
    });

    return response.data;
  }

  async createSchedules({
    eventId,
    dateEvent,
    startTime,
    endTime,
  }: ICreateSchedules): Promise<any> {
    const response = await api.post(`/schedules`, {
      event_id: eventId,
      start_time: startTime,
      end_time: endTime,
      date_event: dateEvent,
    });

    return response.data;
  }
}

export default new EventService();
