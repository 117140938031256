import React, { useRef } from 'react';
import { IconMenu } from '~/assets/Icons';
import {
  Content,
  Menu,
  Header,
  HeaderButton,
  Screen,
  Container,
} from './styles';
import { usePageContext } from '~/hooks';
import { ItemMenu } from '~/components';

const SideMenu: React.FC = () => {
  const { collapseMenu, setCollapseMenu, fixed } = usePageContext();
  const menuRef = useRef(null);

  return (
    <Screen estado={collapseMenu}>
      <Container>
        <Content
          estado={collapseMenu}
          fix={fixed}
          ref={menuRef}
          className="menu"
        >
          <Header>
            <HeaderButton Pin={false} onClick={() => setCollapseMenu(false)}>
              <IconMenu />
            </HeaderButton>
          </Header>
          <Menu ref={menuRef}>
            <ItemMenu />
          </Menu>
        </Content>
      </Container>
    </Screen>
  );
};

export default SideMenu;
