export const isEmpty = (item: string | Object): Boolean => {
  if (typeof item === 'string') {
    return item.trim().length === 0;
  }

  if (item instanceof Object) {
    return Object.entries(item).length === 0;
  }

  return false;
};
