import api from './api';

interface IChurchUser {
  name: string;
  cpf: string | number;
  phone: string | number;
  email: string;
  password: string;
  churchId: string | number;
}

interface IEditUser {
  name: string;
  cpf: string;
  phone: string | number;
  password: string;
  email: string;
  churchId: string | number;
  id: number | string;
}

interface IListFrequency {
  // limit?: number;
  page?: number;
  church?: string;
  year?: string;
}

interface IFrequency {
  date: Date;
  // churchId: string | number;
  leader_id?: number;
  pastor_present: boolean;
  tithes: string;
  offers: string;
  comments: string;
  classes: {
    id: string;
    attendance: string;
  }[];
}

class UserService {
  async createUserBS({
    name,
    cpf,
    password,
    phone,
    email,
    churchId,
  }: IChurchUser): Promise<any> {
    const response = await api.post(`/bs/users`, {
      name,
      cpf,
      password,
      phone,
      email,
      church_id: churchId,
    });

    return response.data;
  }

  async editUserBS({
    name,
    password,
    phone,
    email,
    churchId,
    id,
    cpf,
  }: IEditUser): Promise<any> {
    if (password === '******') {
      const response = await api.put(`/bs/users/${id}`, {
        name,
        phone,
        email,
        cpf,
        church_id: churchId,
      });
      return response.data;
    }
    const response = await api.put(`/bs/users/${id}`, {
      name,
      password,
      email,
      phone,
      cpf,
      church_id: churchId,
    });

    return response.data;
  }

  async listFrequency({
    page = 1,
    church,
    year,
  }: IListFrequency): Promise<any> {
    const response = await api.get(`/bs/frequencies`, {
      params: {
        page,
        limit: 20,
        church,
        year,
      },
    });

    return response.data;
  }

  async deleteFrequency(id: string | number): Promise<any> {
    const response = await api.delete(`/bs/frequencies/${id}`);

    return response.data;
  }

  async CreateFrequency(
    data: IFrequency,
    churchId: string | number,
  ): Promise<any> {
    const response = await api.post(`/bs/frequencies/church/${churchId}`, data);

    return response.data;
  }

  async ListUpdateClasseRequest(): Promise<any> {
    const response = await api.get(
      `/update-classes-request/year/2023/quarter/2`,
    );

    return response.data;
  }

  async EditFrequency(
    data: IFrequency,
    id: string | number,
    churchId: string | number,
  ): Promise<any> {
    const response = await api.put(
      `/bs/frequencies/${id}/church/${churchId}`,
      data,
    );

    return response.data;
  }
}

export default new UserService();
