import React, { useEffect, useState } from 'react';
import { IoEyeSharp } from 'react-icons/io5';
// import { BsGraphUp, BsGraphDown } from 'react-icons/bs';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { useHistory } from 'react-router-dom';
import {
  Page,
  TablePage,
  Loading,
  ModalDefault,
  PageHeader,
  ModalDelete,
  Button,
  Select,
} from '~/components';
import { Content, Actions, Header } from './styles';
import ModalDetails from './components/ModalDetails';
import BiblicalSchool from '~/services/BiblicalSchool';
import ReportsService from '~/services/reportsService';
import { BtEdit, BtTrash } from './Church/styles';
import { IconDelete, IconEdit } from '~/assets/Icons';

const EBD: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [isVisibleModalInfo, setIsVisibleModalInfo] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [menssageModalErro] = useState('');
  const [handleIdInfo, setHandleIdInfo] = useState([]);
  const [input, setInput] = useState('');
  const [time, setTime] = useState<any>(null);
  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [handleIdDelete, setHandleIdDelete] = useState(0);
  const [yearSelect, setYearSelect] = useState('2023');
  const history = useHistory();

  const loadClasses = async ({
    page,
    church,
  }: {
    page: number;
    church?: string;
  }) => {
    setLoading(true);
    const res = await BiblicalSchool.listFrequency({
      page,
      church,
      year: yearSelect,
    });
    setDataSource(res.result);
    setPage(res.info.page);
    setPages(res.info.pages);
    setLoading(false);
  };
  useEffect(() => {
    loadClasses({ page, church: input });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, yearSelect]);

  const columns = [
    {
      title: 'Igreja',
      dataIndex: 'church',
      key: 'church',
      render: (e: any) => e.name,
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (e: any) =>
        `${e && format(new Date(e), "dd 'de' MMMM", { locale: pt })}`,
    },
    {
      title: 'F. do dirigente',
      dataIndex: 'pastor_present',
      key: 'pastor_present',
      render: (e: boolean) => (e ? 'Presente' : 'Ausente'),
    },
    {
      title: 'F. da igreja',
      dataIndex: 'totalPresence',
      key: 'totalPresence',
      render: (e: number) => `${e} alunos`,
    },
    // {
    //   title: 'Variação',
    //   dataIndex: 'increasedPresence',
    //   key: 'increasedPresence',
    //   render: (e: number) =>
    //     e > 0 ? (
    //       <Positive>
    //         {e}% <BsGraphUp />
    //       </Positive>
    //     ) : (
    //       <Negative>
    //         {e}% <BsGraphDown />
    //       </Negative>
    //     ),
    // },
    {
      // title: 'Ver mais',
      key: 'frequencyClasse',
      width: '60px',
      render: (e: any) => (
        <Actions>
          <button
            type="button"
            onClick={() => {
              setHandleIdInfo(e);
              setIsVisibleModalInfo(true);
            }}
          >
            <IoEyeSharp />
          </button>
          <BtTrash
            onClick={() => {
              setHandleIdDelete(e.id);
              setIsVisibleModalDelete(true);
            }}
          >
            <IconDelete />
          </BtTrash>
          <BtEdit onClick={() => history.push(`/frequencia-ebd/${e.id}`)}>
            <IconEdit />
          </BtEdit>
        </Actions>
      ),
    },
  ];

  const deleteReports = async () => {
    setLoading(true);
    await BiblicalSchool.deleteFrequency(handleIdDelete);
    setIsVisibleModalDelete(false);
    setHandleIdDelete(0);
    setPage(1);
    await loadClasses({ page: 1, church: '' });
  };

  const doSearch = async (val: string) => {
    setPage(1);
    loadClasses({ page: 1, church: val });
  };

  // useEffect(() => {
  //   doSearch('');
  // }, []);

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalDelete && (
        <ModalDelete
          text="relatório"
          onClickCancel={() => {
            setIsVisibleModalDelete(false);
            setHandleIdDelete(0);
          }}
          onClickDelete={() => deleteReports()}
        />
      )}
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          {menssageModalErro}
        </ModalDefault>
      )}
      {isVisibleModalInfo && (
        <ModalDetails
          onClick={() => setIsVisibleModalInfo(false)}
          list={handleIdInfo}
        />
      )}

      <Page>
        <PageHeader
          title="Relatório EBD"
          button="Exportar para o excel"
          onClick={() => ReportsService.exportExcel()}
          value={input}
          onChange={e => {
            setInput(e);
            if (time !== null) {
              clearTimeout(time);
            }
            setTime(
              setTimeout(() => {
                doSearch(e);
              }, 1000),
            );
          }}
        />
        <Header>
          <Select
            lista={[
              { name: '2022', id: 1 },
              { name: '2023', id: 2 },
            ]}
            currentValue={yearSelect}
            erro=""
            label=""
            onClickList={e => {
              setYearSelect(e.name);
              setPage(1);
            }}
          />
          <Button onClick={() => history.push('/frequencia-ebd/criar')}>
            Criar Frequência
          </Button>
        </Header>
        <Content>
          <TablePage
            columns={columns}
            dataSource={dataSource}
            pagination={{
              current: page,
              showSizeChanger: false,
              pageSize: 20,
              total: pages * 20,
              onChange: e => {
                setPage(e);
              },
            }}
            rowKey="id"
          />
        </Content>
      </Page>
    </>
  );
};

export default EBD;
