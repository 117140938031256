import styled from 'styled-components';

export const DeletDiv = styled.div`
  button {
    width: 40%;
  }
`;

export const TitleModal = styled.div`
  svg {
    color: #30b4e5;
  }
  button {
    width: fit-content !important;
  }
  display: flex;
  justify-content: right;
  margin: -10px -10px 0 0;
`;

export const ContentDelete = styled.div`
  margin-top: 40px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;
`;
