import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconEdit } from '~/assets/Icons';
import { Page, TablePage, ModalDelete, Loading, Input } from '~/components';
import churchService from '~/services/churchService';
import { BtEdit, Content, Header } from './styles';

const Churchs: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [, setPages] = useState(0);
  const [limit] = useState(10);
  const [dataSource, setDataSource] = useState([]);
  const [input, setInput] = useState('');
  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [handleIdDelete, setHandleIdDelete] = useState(0);

  const history = useHistory();

  const loadChurchs = async ({
    // limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    setLoading(true);
    const res = await churchService.listChurch({
      // limit,
      page,
    });

    setDataSource(res.result);
    setPage(res.info.page);
    setPages(res.info.pages);
    setLoading(false);
  };

  useEffect(() => {
    loadChurchs({ limit, page });
  }, [limit, page]);

  const columns = [
    {
      title: 'Igrejas',
      dataIndex: 'name',
      // key: 'name',
    },
    {
      title: 'Setor',
      dataIndex: 'sector',
      // key: 'sector_id',
      render: (e: { name: string }) => e.name,
    },
    {
      title: 'Bairro',
      dataIndex: 'address',
      // key: 'address_id',
      render: (e: { district: string }) => e?.district,
    },
    {
      title: 'Ações',
      key: 'action',
      width: '100px',
      dataIndex: 'id',
      render: (e: number) => (
        <>
          <BtEdit onClick={() => history.push(`/igreja-ebd/${e}`)}>
            <IconEdit />
          </BtEdit>
        </>
      ),
    },
  ];

  const deleteSector = async () => {
    setLoading(true);
    await churchService.deletechurchById(handleIdDelete);
    setIsVisibleModalDelete(false);
    loadChurchs({ limit: 10, page });
    setLoading(false);
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalDelete && (
        <ModalDelete
          text="igreja"
          onClickCancel={() => {
            setIsVisibleModalDelete(false);
            setHandleIdDelete(0);
          }}
          onClickDelete={() => deleteSector()}
        />
      )}
      <Page>
        <Header>
          Igrejas <Input onChange={e => setInput(e.target.value)} />
        </Header>
        <Content>
          <TablePage
            columns={columns}
            dataSource={dataSource.filter((element: any) =>
              element.name.toLowerCase().includes(input.toLowerCase()),
            )}
            // pagination={{
            //   current: page,
            //   showSizeChanger: false,
            //   pageSize: limit,
            //   total: pages * limit,
            //   onChange: e => {
            //     setPage(e);
            //   },
            // }}
            rowKey="id"
          />
        </Content>
      </Page>
    </>
  );
};

export default Churchs;
