import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Input,
  Loading,
  ModalDefault,
  Page,
  PageHeader,
  Select,
} from '~/components';
import areasService from '~/services/areasService';
import SectorService from '~/services/sectorService';
import { generateRequiredInputValues, isEmpty, validateForm } from '~/utils';
import { Container, Content } from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const EditSector: React.FC = () => {
  const history = useHistory();
  const { type } = useParams<{ type: string }>();
  const [loading, setLoading] = useState(false);
  const [typePage, setTypePage] = useState(false);
  const [listArea, setListArea] = useState([]);
  const [area, setArea] = useState({ id: 0, name: '' });
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalSucess, setIsVisibleModalSucess] = useState(false);

  const stateSchema = {
    ...generateRequiredInputValues(['name', 'areaId']),
  };

  const [inputValue, setInputValue] = useState<typeInput>(stateSchema);

  useEffect(() => {
    if (type === 'create') {
      setTypePage(true);
    } else {
      const loadSector = async () => {
        setLoading(true);
        const response = await SectorService.getSectorById(type);
        setInputValue((prevState: any) => ({
          ...prevState,
          name: {
            ...prevState.name,
            value: response.name,
          },
          areaId: {
            ...prevState.areaId,
            value: response.area_id,
          },
        }));
        setArea({ id: response.area.id, name: response.area.name });
        setLoading(false);
      };
      loadSector();
    }
  }, [type]);

  useEffect(() => {
    const loadArea = async () => {
      const response = await areasService.listArea({ limit: 200, page: 1 });
      setListArea(response.result);
    };

    loadArea();
  }, []);

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputValue[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputValue(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputValue[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputValue);
    let hasError = false;

    Object.entries(inputValue).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    Object.keys(inputValue).forEach(inputName => {
      if (inputValue[inputName].error) {
        hasError = true;
      }
    });
    return isEmpty(inputsWithError) && !hasError;
  };

  const toSave = async () => {
    if (typePage) {
      if (isFormValid()) {
        try {
          await SectorService.CreateSector({
            name: inputValue.name.value,
            areaId: inputValue.areaId.value,
          });
          setIsVisibleModalSucess(true);
        } catch {
          setIsVisibleModalErro(true);
        }
      } else {
        setIsVisibleModalErro(true);
      }
    } else if (isFormValid()) {
      SectorService.editSectorById({
        id: type,
        name: inputValue.name.value,
        areaId: inputValue.areaId.value,
      });
      setIsVisibleModalSucess(true);
    } else {
      setIsVisibleModalErro(true);
    }
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Não foi possível salvar!
        </ModalDefault>
      )}
      {isVisibleModalSucess && (
        <ModalDefault success onClick={() => history.push('/setores')}>
          Cadastro finalizado com sucesso!
        </ModalDefault>
      )}
      <Page>
        <PageHeader
          title={typePage ? 'Criar Setor' : 'Editar Setor'}
          button="Salvar"
          onClick={() => toSave()}
        />
        <Container>
          <Content>
            <Input
              label="Nome"
              placeholder="Ex.: Setor 12"
              requiredLabel
              value={inputValue.name.value}
              onChange={e => handleInput(e.target.value, 'name')}
              error={inputValue.name.error}
            />

            <Select
              label="Área"
              onClickList={item => {
                setArea(item);
                handleInput(item.id, 'areaId');
              }}
              currentValue={area.name}
              lista={listArea}
              erro={inputValue.areaId.error}
            />
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default EditSector;
