import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 3%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  width: 100%;
  height: fit-content;
`;

export const ColorInput = styled.span`
  input {
    padding: 0 !important;
  }
`;

export const ImgZone = styled.img`
  width: 200px;
  height: 186px;
  object-fit: cover;
  border-radius: 10px;
`;

export const ContentUpload = styled.div`
  width: 200px;
  height: 186px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 40%;
  }
`;

export const ContainerUpload = styled.div`
  width: 200px;
  height: 186px;
  background: #c4c4c4;
  border-radius: 6px;
  margin-top: 20px;
  cursor: pointer;

  p {
    font-size: 10px;
    text-align: center;
  }
`;

export const InputBox = styled.div`
  width: 100%;
  margin-left: 3%;
`;
