import styled from 'styled-components';

export const TitleModal = styled.div`
  b {
    font-size: 20px;
  }
  svg {
    color: #488cfa;
  }
  display: flex;
  justify-content: right;
  margin-bottom: -20px;
`;

export const Content = styled.div`
  margin-top: 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;

  b {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
  }
`;
